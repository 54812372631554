import React, { useState, useRef, useEffect } from 'react';
import { Building2, Home, Users, Shield, Wrench, Network, Facebook, Linkedin, Menu, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import logo from '../../img/home/homeandbusiness.png';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const navItemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    hover: { 
      scale: 1.1,
      textShadow: "0 0 8px rgb(255,255,255)",
      transition: { type: "spring", stiffness: 300 }
    }
  };

  const logoVariants = {
    hidden: { x: -50, opacity: 0, rotate: -180 },
    visible: { 
      x: 0, 
      opacity: 1, 
      rotate: 0,
      transition: { 
        type: "spring",
        stiffness: 200,
        damping: 20
      }
    },
    hover: {
      scale: 1.1,
      rotate: 360,
      transition: { duration: 0.8 }
    }
  };

  const socialIconVariants = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
    hover: { 
      scale: 1.2,
      rotate: [0, 15, -15, 0],
      transition: { duration: 0.5 }
    }
  };

  return (
<nav className="fixed top-0 left-0 right-0 bg-[#7A1612] shadow-lg z-50">
<div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo Section */}
          <motion.div 
            variants={logoVariants}
            initial="hidden"
            animate="visible"
            whileHover="hover"
            onClick={() => navigate('/')}
            className="flex-shrink-0 flex items-center cursor-pointer"
          >
            <img src={logo} alt="Logo" className="h-32 w-auto" />
          </motion.div>

          {/* Navigation Links */}
          <div className="hidden md:flex items-center justify-center flex-1 space-x-8">
            {['Inicio', 'Nosotros', 'Divisiones'].map((item, index) => (
              <motion.div
                key={item}
                variants={navItemVariants}
                initial="hidden"
                animate="visible"
                whileHover="hover"
                transition={{ delay: index * 0.1 }}
                className="relative"
              >
                {item === 'Divisiones' ? (
                  <div 
                    ref={menuRef}
                    className="relative"
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                  >
                    <button
                      className="inline-flex items-center px-1 pt-1 text-white cursor-pointer focus:outline-none"
                    >
                      <Wrench className="h-4 w-4 mr-1" />
                      {item}
                    </button>
                    
                    <AnimatePresence>
                      {isDropdownOpen && (
                        <motion.div
                          ref={dropdownRef}
                          initial={{ opacity: 0, y: -10, scale: 0.95 }}
                          animate={{ opacity: 1, y: 0, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.95, transition: { duration: 0.2 } }}
                          transition={{ type: "spring", stiffness: 300, damping: 25 }}
                          className="absolute z-50 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                          style={{ minWidth: '12rem' }}
                        >
                          {['Operaciones', 'Infraestructura', 'Ciberseguridad'].map((subItem, subIndex) => (
                            <motion.a
                              key={subItem}
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                if (subItem === 'Operaciones') {
                                  navigate('/Operation-info');
                                } else if (subItem === 'Infraestructura') {
                                  navigate('/infrastructure-info');
                                } else if (subItem === 'Ciberseguridad') {
                                  navigate('/cybersecurity-info');
                                }
                                setIsDropdownOpen(false);
                              }}
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ delay: subIndex * 0.1 }}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                              whileHover={{ x: 10, backgroundColor: "#f3f4f6" }}
                            >
                              {subItem === 'Operaciones' && <Wrench className="h-4 w-4 mr-2" />}
                              {subItem === 'Infraestructura' && <Network className="h-4 w-4 mr-2" />}
                              {subItem === 'Ciberseguridad' && <Shield className="h-4 w-4 mr-2" />}
                              {subItem}
                            </motion.a>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <a 
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (item === 'Inicio') {
                        navigate('/tn-home');
                      } else if (item === 'Nosotros') {
                        navigate('/about');
                      }
                    }}
                    className="inline-flex items-center px-1 pt-1 text-white"
                  >
                    {item === 'Inicio' && <Home className="h-4 w-4 mr-1" />}
                    {item === 'Nosotros' && <Users className="h-4 w-4 mr-1" />}
                    {item}
                  </a>
                )}
              </motion.div>
            ))}
          </div>

          {/* Social Media Links */}
          <div className="hidden md:flex items-center space-x-4">
            {[
              { Icon: Facebook, href: 'https://www.facebook.com/tatningardcasa' },
              { Icon: Linkedin, href: 'https://www.linkedin.com/company/tatningard-group/posts/?feedView=all' }
            ].map(({ Icon, href }, index) => (
              <motion.a
                key={index}
                href={href}
                variants={socialIconVariants}
                initial="hidden"
                animate="visible"
                whileHover="hover"
                transition={{ delay: index * 0.2 }}
                className="text-white p-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="h-5 w-5" />
              </motion.a>
            ))}
          </div>

          {/* Mobile menu button */}
          <motion.div 
            className="md:hidden flex items-center"
            whileTap={{ scale: 0.9 }}
          >
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </motion.div>
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-[#7A1612]"
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              {['Inicio', 'Nosotros', 'Operaciones', 'Infraestructura', 'Ciberseguridad'].map((item) => (
                <motion.a
                  key={item}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (item === 'Inicio') {
                      navigate('/tn-home');
                    } else if (item === 'Nosotros') {
                      navigate('/about');
                    } else if (item === 'Operaciones') {
                      navigate('/Operation-info');
                    } else if (item === 'Infraestructura') {
                      navigate('/infrastructure-info');
                    } else if (item === 'Ciberseguridad') {
                      navigate('/cybersecurity-info');
                    }
                    setIsOpen(false);
                  }}
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-red-800"
                >
                  <div className="flex items-center">
                    {item === 'Inicio' && <Home className="h-4 w-4 mr-2" />}
                    {item === 'Nosotros' && <Users className="h-4 w-4 mr-2" />}
                    {item === 'Operaciones' && <Wrench className="h-4 w-4 mr-2" />}
                    {item === 'Infraestructura' && <Network className="h-4 w-4 mr-2" />}
                    {item === 'Ciberseguridad' && <Shield className="h-4 w-4 mr-2" />}
                    {item}
                  </div>
                </motion.a>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navigation;