import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import CybersecurityInfo from "./Pages/CybersecurityInfo"; 
import InfrastructureInfo from "./Pages/InfrastructureInfo";
import OperationInfo from "./Pages/OperationInfo";
import Infraestructura from "./Pages/TnDivisiones/Infraestructura";
import Cybersecurity from "./Pages/TnDivisiones/Cybersecurity";
import NewHome2 from "./Pages/NewHome2";
import TnHome2 from "./Pages/TnHome2";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import About from "./Pages/TnPages/About";
import TnPageNew from "./Pages/TnPages/TnPageNew";
import BackgroundAbout from "./components/TnGroupComponents/BackgroundAbout";
import AboutNew from './Pages/AboutNew'

import Privacy from './Pages/TnPages/Privacy'
import StrategicRiskUnit from "./Pages/TatninDivisions/StrategicRiskUnit";
import OperationsNew from "./Pages/TatninDivisions/OperationsNew";
import Operations from './Pages/TnDivisiones/Operations'


function App() {
  return (
    <ChakraProvider>
      <Router>
        <ScrollToTop/>
        <Routes>
          {/* Ruta principal para la página Home */}
          <Route path="/" element={<NewHome2 />} />
          {/* Ruta para la página TnHome */}
          <Route path="/tn-home" element={<TnHome2 />} />
          {/* Rutas de más información */}
          <Route path="/cybersecurity-info" element={<CybersecurityInfo />} /> 
          <Route path="/infrastructure-info" element={<InfrastructureInfo />} />
          <Route path="/Operation-info" element={<OperationInfo />} />

          {/* Nosotros */}
          <Route path="/about" element={<AboutNew/>} />


          {/* Aviso de privacidad */}
          <Route path="/privacy" element={<PrivacyPolicy/>} />






          <Route path="/tn-group" element={<TnPageNew/>}/>
          {/* Pagina de about Tatnin */}
          <Route path="/tn-about" element={<About/>}/>

          {/* Rutas para mas informacion en tatnning */}
          <Route path="/tn-infraestructure-info" element={<Infraestructura/>} />
          <Route path="/tn-cibersecurity-info" element={<Cybersecurity/>} />
          <Route path="/tn-operations-info" element={<Operations/>} />
          {/* Nuevas divisiones */}
          <Route path="/tn-strategic-info" element={<StrategicRiskUnit/>} />

          <Route path="/tn-privacy" element={<Privacy/>}/>






          {/* Aqui es donde pruebo los componentes */}
          <Route path="/test" element={<BackgroundAbout/>} />



        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
