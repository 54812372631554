import {
  Box,
  Text,
  HStack,
  VStack,
  Divider,
  Icon,
  Image,
} from "@chakra-ui/react";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../img/home/homeandbusiness.png";

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handlePrivacyClick = () => {
    navigate("/privacy");
  };

  return (
    <Box bg="#7A1612" color="white" py={{ base: 4, md: 6 }} px={8} as="footer">
      {/* Contenedor principal */}
      <VStack spacing={{ base: 4, md: 6 }} align="center" w="full">
        
        {/* Logo y descripción */}
        <VStack spacing={2} align="center" textAlign="center" w="full">
          <Image 
            src={logo} 
            alt="Tatningard Logo" 
            boxSize={{ base: "100px", md: "150px" }} 
          />
          <Text fontSize={{ base: "xs", md: "sm" }} w="full" px={4}>
            <b>Tatningard Home&Business</b> ofrece soluciones integrales para infraestructura, ciberseguridad y operaciones. Contáctanos para más información.
          </Text>
        </VStack>

        {/* Redes sociales */}
        <Divider borderColor="whiteAlpha.600" w="80%" />
        <VStack spacing={1} align="center">
          <Text fontSize={{ base: "sm", md: "md" }} fontWeight="bold">
            Redes sociales
          </Text>
          <HStack spacing={4}>
            <a
              href="https://www.linkedin.com/company/tatningard-group/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaLinkedin} boxSize={{ base: 5, md: 6 }} />
            </a>
            <a
              href="https://www.facebook.com/tatningardcasa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={FaFacebook} boxSize={{ base: 5, md: 6 }} />
            </a>
          </HStack>
        </VStack>

        {/* Divider */}
        <Divider borderColor="whiteAlpha.600" w="80%" />

        {/* Derechos reservados */}
        <VStack spacing={1}>
          <Text fontSize="xs" textAlign="center" w="full">
            © {currentYear} Tatningard Home&Business. Todos los derechos reservados.
          </Text>
          <Text
            as="span"
            fontSize="xs"
            fontWeight="bold"
            textDecoration="underline"
            _hover={{ cursor: "pointer" }}
            onClick={handlePrivacyClick}
          >
            Aviso de Privacidad
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Footer;