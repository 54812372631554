import React from 'react';
import { Building2, Package, HardHat, ConciergeBell } from 'lucide-react';

// Card en JSX (sin TypeScript)
const Card = ({ title, items, bgColor, icon, iconPosition }) => {
  return (
    <div className="relative flex flex-col mb-12 md:mb-0">
      {/* Si el iconPosition es "top", se muestra arriba en TODAS las pantallas */}
      {iconPosition === 'top' && (
        <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-white rounded-full p-3 border-4 border-white z-10">
          {icon}
        </div>
      )}

      {/* Si el iconPosition es "bottom", en pantallas grandes (md+) se muestra abajo */}
      {iconPosition === 'bottom' && (
        <>
          {/* Ícono para DESKTOP (se oculta en mobile) */}
          <div className="hidden md:block absolute -bottom-12 left-1/2 transform -translate-x-1/2 bg-white rounded-full p-3 border-4 border-white z-10">
            {icon}
          </div>
          {/* Ícono para MOBILE (se oculta en pantallas md+) */}
          <div className="block md:hidden absolute -top-8 left-1/2 transform -translate-x-1/2 bg-white rounded-full p-3 border-4 border-white z-10">
            {icon}
          </div>
        </>
      )}

      {/* Tarjeta con altura fija y sin scroll */}
      <div className="border-2 border-[#444] rounded-lg bg-white mt-8 h-[480px] flex flex-col">
        {/* Encabezado con altura fija (barra gris) */}
        <div className={`${bgColor} text-white font-bold text-xl relative h-[80px] flex items-center justify-center px-4 leading-snug`}>
          {/* Flecha en el extremo derecho */}
          <div className={`absolute right-0 top-0 bottom-0 w-8 h-full ${bgColor} clip-arrow`} />
          
          {/* Contenido del título (permite varias líneas si es largo) */}
          <div className="text-center w-full whitespace-normal">
            {title.includes(' Y ')
              ? (
                <>
                  {title.split(' Y ')[0]}
                  <br />
                  {`Y ${title.split(' Y ')[1]}`}
                </>
              ) : (
                title
              )
            }
          </div>
        </div>
        
        {/* Contenido principal sin scroll */}
        <div className="p-4 md:p-6 flex-1">
          <ul className="space-y-2 md:space-y-3">
            {items.map((item, index) => (
              <li key={index} className="flex items-start">
                <span className="mr-2 text-lg flex-shrink-0">•</span>
                <span className="text-sm md:text-base">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const EsquemaUnidadRiesgo = () => {
  const cards = [
    {
      title: 'GOBIERNO',
      bgColor: 'bg-[#666]',
      items: [
        'Defensa e inteligencia.',
        'Seguridad interna.',
        'Operaciones de contingencia.',
        'Desarrollo y formación de personal.'
      ],
      icon: <Building2 className="w-10 h-10 text-[#333]" />,
      iconPosition: 'top'
    },
    {
      title: 'INFRAESTRUCTURA CRÍTICA',
      bgColor: 'bg-[#666]',
      items: [
        'Plantas de energía, telecomunicaciones y aeropuertos.',
        'Puertos marítimos.',
        'Vías férreas.',
        'Ciudades seguras y puntos críticos.',
        'Transporte de mercancía sensible'
      ],
      icon: <HardHat className="w-10 h-10 text-[#333]" />,
      iconPosition: 'bottom'
    },
    {
      title: 'MANUFACTURA Y CENTROS DE DISTRIBUCIÓN',
      bgColor: 'bg-[#666]',
      items: [
        'Análisis de riesgo.',
        'Cadena logística.',
        'Pérdida de mercancía.'
      ],
      icon: <Package className="w-10 h-10 text-[#333]" />,
      iconPosition: 'top'
    },
    {
      title: 'HOSPITALIDAD Y ENTRETENIMIENTO',
      bgColor: 'bg-[#666]',
      items: [
        'Auditoría en activos.',
        'Planes de contingencia.',
        'Política de gestión de crisis.',
        'Protección de imagen pública ante incidentes.'
      ],
      icon: <ConciergeBell className="w-10 h-10 text-[#333]" />,
      iconPosition: 'bottom'
    }
  ];

  return (
    <div className="min-h-screen bg-[#6b1d1d] flex flex-col items-center justify-center p-4">
      {/* Título principal */}
      <h2 className="text-3xl font-bold text-white mb-8">
        SECTORES DE ESPECIALIDAD
      </h2>

      <div className="w-full max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8 relative">
          {cards.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              items={card.items}
              bgColor={card.bgColor}
              icon={card.icon}
              iconPosition={card.iconPosition}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EsquemaUnidadRiesgo;