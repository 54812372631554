import React from 'react'
import Navbar from '../../components/TnGroupComponents/NavbarTn'
import { TitleSection } from '../../components/TnGroupComponents/TitleSection'
import CardsOperationNew from '../../components/TnGroupComponents/CardsOperationNew'
import { PresentationOperations } from '../../components/TnGroupComponents/PresentationOperations'
import Footer from '../../components/TnGroupComponents/Footer'

const OperationsNew = () => {
  return (
    <>
      
      <div className="relative z-50">
        <Navbar />
      </div>

        {/* Aqui ira el titulo */}
      <div className='bg-black'>
      <div className='mt-20'>
        <TitleSection
        mainTitle="Operaciones"
        subTitle='Tecnologia y Estrategia para la continuidad de su negocio'
      />
      </div>


      <div className='mt-20'>
        <PresentationOperations/>
      </div>

      </div>


        {/* Aqui va el contenido de la pagina  */}
      <div className='mt-20'>
        <CardsOperationNew/>
      </div>

      <Footer/>

    </>
  )
}

export default OperationsNew
