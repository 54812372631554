import React from 'react';
import { Linkedin, Facebook } from 'lucide-react';
import logo from '../../img/home/group.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    navigate('/tn-privacy');
  };

  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Logo */}
          <div className="flex items-center mb-4 md:mb-0">
            <img src={logo} alt="Tatningard Logo" className="h-20 w-auto mr-2" />
            <span className="text-xl font-bold">Tatningard</span>
          </div>

          {/* Redes sociales y aviso */}
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8">
            <div className="flex space-x-4">
              <a
                href="https://www.linkedin.com/company/tatningard-group/posts/?feedView=all"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 transition-colors"
                aria-label="LinkedIn"
              >
                <Linkedin size={24} />
              </a>
              <a
                href="https://www.facebook.com/tatningard"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 transition-colors"
                aria-label="Facebook"
              >
                <Facebook size={24} />
              </a>
            </div>

            <a
              href="#"
              onClick={handlePrivacyClick}
              className="text-sm hover:text-blue-400 transition-colors cursor-pointer"
            >
              Aviso de Privacidad
            </a>
          </div>
        </div>

        {/* Derechos reservados */}
        <div className="mt-8 text-center text-sm text-gray-400">
          © {new Date().getFullYear()} Tatningard. Todos los derechos reservados.
        </div>
      </div>
    </footer>
  );
};

export default Footer;