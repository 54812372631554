// OperationsContent.jsx
import React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

// Importa tus componentes reutilizables
import WhatWeDoSection from "../WhatWeDoSection";
import ReusableCard from "../ReusableCard";

// Importa tus imágenes
import operacionesIMG from '../../../img/pagetatnin/principaloperaciones.jpeg'
import EsquemaOpera from "./EsquemaOpera";

// Configuración de las animaciones
const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.5,
    },
  },
};

const OperationsContent = ({
  // Props opcionales para la sección "¿Qué hacemos?"
  whatWeDoImage = operacionesIMG,
  whatWeDoTitle = "¿Qué hacemos?",
  whatWeDoDescription = "Implementamos estrategias técnicas y tácticas aplicables en diversos sectores, cuyas operaciones se vean afectadas por amenazas internas o externas. Utilizamos diversas herramientas tecnológicas que permiten asegurar el flujo continuo de las operaciones de nuestros clientes."
}) => {
  const soluciones = [
    {
      title: "SERVICIOS",
      items: [
        "Consultoría en prevención y gestión de riesgos.",
        "Adiestramiento.",
        "Pruebas de control y confianza.",
        "Manuales operativos.",
        "Planes de contingencia.",
        "Contraterrorismo."
      ],
    },
    {
      title: "EQUIPAMIENTO",
      items: [
        "Radiocomunicación.",
        "Balístico.",
        "Médico táctico.",
        "Protección de activos.",
        "Sistema de posicionamiento.",
        "Comunicación satelital.",
        "Cajas de seguridad."
      ],
    },
  ];

  return (
    <>
      {/* Sección "¿Qué hacemos?" utilizando props para imagen y descripción */}
      <div className="flex items-center justify-center bg-gray-800">
        <WhatWeDoSection
          image={whatWeDoImage}
          title={whatWeDoTitle}
          description={whatWeDoDescription}
          altText="Operaciones"
        />
      </div>

      {/* Sección Diagrama */}
      <div className="p-6 text-center bg-black">
        
        <Flex justify="center" align="center">
          <EsquemaOpera/>
        </Flex>
      </div>

      {/* Sección de Soluciones (cartas centradas y proporcionadas con Grid) */}
      <div className="p-6 text-center bg-gray-800">
        <h3 className="text-3xl font-semibold mb-6 text-white">SOLUCIONES</h3>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 gap-6 items-stretch max-w-6xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {soluciones.map((solucion, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              className="flex flex-col h-full"
            >
              <ReusableCard title={solucion.title} items={solucion.items} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default OperationsContent;