// CiberContent.jsx
import React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

// Importa tus componentes reutilizables
import WhatWeDoSection from "../WhatWeDoSection";
import ReusableCard from "../ReusableCard";

// Importa tus imágenes
import ciberseguridadIMG from '../../../img/pagetatnin/ciberPrincipal.jpeg'
import EsquemaCiber from "./EsquemaCiber";

// Configuración de las animaciones
const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.5,
    },
  },
};

const CiberContent = ({
  // Props opcionales para modificar la sección "¿Qué hacemos?"
  whatWeDoImage = ciberseguridadIMG,
  whatWeDoTitle = "¿Qué hacemos?",
  whatWeDoDescription = `Aseguramos la continuidad operativa de los activos críticos de las organizaciones,
previniendo la exposición de la información confidencial causada por brechas en el Sistema de Gestión de la Seguridad de la Información.
Contamos con recursos que nos permiten diagnosticar, proteger, optimizar y dar respuesta reactiva ante cualquier incidente en la infraestructura tecnológica,
procurando identificar la trazabilidad y daños del ataque.`
}) => {
  // Ajusta aquí las 3 columnas de la imagen: SERVICIOS, FÁBRICA DE SOFTWARE, CONTROLES
  const soluciones = [
    {
      title: "SERVICIOS",
      items: [
        "Consultoría de seguridad de la información.",
        "Análisis de vulnerabilidades y pruebas de penetración.",
        "Recuperación por ataques de Ransomware.",
        "Análisis forense.",
        "Cumplimiento normativo."
      ],
    },
    {
      title: "FÁBRICA DE SOFTWARE",
      items: [
        "Desarrollo Web, apps móviles y realidad aumentada.",
        "CRM - Gestión de las relaciones con los clientes.",
        "ERP - Planificación de recursos empresariales."
      ],
    },
    {
      title: "CONTROLES",
      items: [
        "Suites de Protección a Usuario (C.E.M.A).",
        "Continuidad para Datos Críticos (C.B.D.R).",
        "Data center y nube.",
        "USMI (Administración de Seguridad Unificada) física y lógica."
      ],
    },
  ];

  return (
    <>
      {/* Sección "¿Qué hacemos?" */}
      <div className="flex items-center justify-center p-6 bg-gray-800">
        <WhatWeDoSection
          image={whatWeDoImage}
          title={whatWeDoTitle}
          description={whatWeDoDescription}
          altText="Operaciones"
        />
      </div>

      {/* Sección Diagrama */}
      <div className="p-6 text-center bg-gray-400">
        
        <Flex justify="center" align="center">
          <EsquemaCiber/>
        </Flex>
      </div>

      {/* Sección de Soluciones (cartas centradas y proporcionadas con Grid) */}
      <div className="p-6 text-center bg-gray-800">
        <h3 className="text-3xl font-semibold mb-6 text-white">SOLUCIONES</h3>
        <motion.div
          // Ajustamos a 3 columnas en pantallas md o superiores
          className="grid grid-cols-1 md:grid-cols-3 gap-6 items-stretch max-w-6xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {soluciones.map((solucion, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              className="flex flex-col h-full"
            >
              <ReusableCard title={solucion.title} items={solucion.items} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default CiberContent;