import React from 'react';
import { motion } from 'framer-motion';

// Variantes para la animación del contenedor y sus elementos
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      // Hace que aparezcan uno tras otro con un ligero retraso
      staggerChildren: 0.2 
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

/**
 * Componente de título con descripción animada.
 * @param {string} mainTitle - Título principal
 * @param {string} subTitle - Descripción o frase secundaria
 */
export function TitleSection({ mainTitle, subTitle }) {
  return (
    <motion.div 
      className="flex flex-col items-center justify-center text-center space-y-4 py-8 bg-black"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h1 
        className="text-3xl md:text-5xl font-bold text-white"
        variants={itemVariants}
      >
        {mainTitle}
      </motion.h1>

      <motion.p 
        className="text-base md:text-lg text-gray-300"
        variants={itemVariants}
      >
        {subTitle}
      </motion.p>
    </motion.div>
  );
}