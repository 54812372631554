import React from 'react';
import evaluacion from '../../../img/pagetatnin/evaluacion.gif'
import diseño from '../../../img/pagetatnin/diseño.gif'
import planeacion from '../../../img/pagetatnin/planeacion.gif'
import ejecucion from '../../../img/pagetatnin/ejecucion.gif'
import final from '../../../img/pagetatnin/final.gif'

const EsquemaInfra = () => {
  const styles = `
    .esquema-infra__wrapper {
      color: black; /* Cambiado de white a black */
      font-family: Arial, sans-serif;
      text-align: center;
      padding: 20px;
    }
    .esquema-infra__heading {
      font-size: 24px;
      margin-bottom: 40px;
      animation: slideIn 1s ease-in-out;
    }
    .esquema-infra__container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 20px;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: auto;
      padding: 40px 20px;
    }
    .esquema-infra__step {
      width: 160px;
      height: 160px;
      padding: 15px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: transform 0.3s, box-shadow 0.3s;
      min-width: 160px;
    }
    .esquema-infra__step:hover {
      transform: scale(1.1);
      box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4);
    }
    .esquema-infra__circle {
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 100%);
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: absolute;
      animation: rotate 5s linear infinite;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    .esquema-infra__step img {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
      z-index: 1;
    }
    .esquema-infra__step p {
      z-index: 1;
      animation: fadeInSlideUp 1s ease-out;
    }
    .esquema-infra__line {
      width: 60px;
      height: 5px;
      background: linear-gradient(to right, gray, white, gray);
      border-radius: 2px;
      animation: glowingLine 2s infinite alternate;
      position: relative;
    }
    @keyframes slideIn {
      from { opacity: 0; transform: translateY(-20px); }
      to { opacity: 1; transform: translateY(0); }
    }
    @keyframes glowingLine {
      0% { background: gray; }
      50% { background: #ffffff; }
      100% { background: gray; }
    }
    @keyframes rotate {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    @keyframes fadeInSlideUp {
      0% { opacity: 0; transform: translateY(20px); }
      100% { opacity: 1; transform: translateY(0); }
    }
    /* Adaptabilidad en Celulares */
    @media (max-width: 768px) {
      .esquema-infra__container {
        flex-wrap: wrap;
        justify-content: center;
      }
      .esquema-infra__step {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
      }
      .esquema-infra__step img {
        width: 40px;
        height: 40px;
      }
      .esquema-infra__step p {
        font-size: 0.9em;
      }
      .esquema-infra__line {
        display: none;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="esquema-infra__wrapper">
        <div className="esquema-infra__container">
          <div className="esquema-infra__step">
            <div className="esquema-infra__circle"></div>
            <img src={evaluacion} alt="Evaluación" />
            <p>Evaluación de Necesidades</p>
          </div>
          <div className="esquema-infra__line"></div>
          <div className="esquema-infra__step">
            <div className="esquema-infra__circle"></div>
            <img src={diseño} alt="Diseño" />
            <p>Diseño y Cálculo del Proyecto</p>
          </div>
          <div className="esquema-infra__line"></div>
          <div className="esquema-infra__step">
            <div className="esquema-infra__circle"></div>
            <img src={planeacion} alt="Planeación" />
            <p>Planeación</p>
          </div>
          <div className="esquema-infra__line"></div>
          <div className="esquema-infra__step">
            <div className="esquema-infra__circle"></div>
            <img src={ejecucion} alt="Ejecución" />
            <p>Ejecución</p>
          </div>
          <div className="esquema-infra__line"></div>
          <div className="esquema-infra__step">
            <div className="esquema-infra__circle"></div>
            <img src={final} alt="Cierre" />
            <p>Cierre de Proyecto</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EsquemaInfra;