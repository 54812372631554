import React from 'react';
import TiltedCard from '../motion/TiltedCard';
import comohacerlo from '../../img/pagetatnin/comolohacemostatnin.jpeg';
import TrueFocus from '../motion/TrueFocus';

const CardHow = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Sección con grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center h-auto">
        {/* Título y texto explicativo */}
        <div className="space-y-6">  {/* Espacio entre el título y el texto */}
          {/* Título con margen inferior */}
          <TrueFocus 
            sentence="¿Cómo lo hacemos?"
            manualMode={false}
            blurAmount={2}
            borderColor="red"
            animationDuration={2}
            pauseBetweenAnimations={1}
          />
          
          {/* Texto explicativo */}
          <p className="text-lg leading-relaxed">
          Con un equipo de ingenieros, técnicos, médicos y agentes de seguridad en disciplinas específicas, logrando desarrollar respuestas eficientes y determinadas a los requerimientos de seguridad más sensibles de nuestros clientes en las áreas físicas, digitales y personales.
          Nuestras operaciones son completamente autónomas, altamente disciplinadas y conscientes de la confidencialidad del cliente y las sensibilidades locales.
          </p>
        </div>

        {/* Carta con efecto */}
        <div className="flex justify-center">
          <TiltedCard
            imageSrc={comohacerlo}
            altText="¿Como lo hacemos?"
            captionText="Kendrick Lamar - GNX"
            containerHeight="300px"
            containerWidth="300px"
            imageHeight="300px"
            imageWidth="300px"
            rotateAmplitude={12}
            scaleOnHover={1.2}
            showMobileWarning={false}
            showTooltip={false}
            displayOverlayContent={true}
            overlayContent={
              <p className="tilted-card-demo-text">
                ¿Como lo hacemos?
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CardHow;