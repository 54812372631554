import React from 'react'
import { CardGovernment } from '../../components/TnGroupComponents/CardGovernment'

import gobierno from '../../img/pagetatnin/gobierno.jpg'
import InfraestructuraCritica from '../../img/pagetatnin/infraestructuraCritica.jpg'
import logistica from '../../img/pagetatnin/logistica.jpg'
import entrenamiento from '../../img/pagetatnin/entrenamiento.jpg'

import Navbar from '../../components/TnGroupComponents/NavbarTn'
import {PresentationStrategicRiskUnit} from '../../components/TnGroupComponents/PresentationStrategicRiskUnit'
import Footer from '../../components/TnGroupComponents/Footer'
import { TitleSection } from '../../components/TnGroupComponents/TitleSection'
import EsquemaUnidadRiesgo from '../../components/TnGroupComponents/Division/EsquemaUnidadRiesgo'

const StrategicRiskUnit = () => {
  return (
    <>
      <div className="relative z-50">
        <Navbar />
      </div>

      <div className='bg-black'>
      <div className='mt-20'>
        {/* Aqui ira el titulo */}
        <TitleSection
        mainTitle="Unidad de Riesgos Estratégicos"
        subTitle='"Protección, continuidad y soluciones para operaciones críticas."'
      />
      </div>

      <div className='mt-20'>

        <PresentationStrategicRiskUnit/>
      </div>
      </div>

      <div>
       <EsquemaUnidadRiesgo/>
      </div>
      

      <Footer/>
    </>
  )
}

export default StrategicRiskUnit