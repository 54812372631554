import React, { useEffect, useState, useRef } from "react";
import "../styles/Tnhomepage.css";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import Navigation from '../components/componentshomeandbusiness/Navigation'

// Imagenes de Valores
import valores from "../img/nuestrosvalores.png";
import elegirnos from "../img/porqueelegirnos.png"


import CirclesDivision from "../components/componentshomeandbusiness/CirclesDivision";




const TnHome2 = () => {
  const [selectedCard, setSelectedCard] = useState("operations");
  const divisionesRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/particles.js";
    script.async = true;
    script.onload = () => {
      if (window.particlesJS) {
        window.particlesJS("particles-js", {
          particles: {
            number: { value: 80, density: { enable: true, value_area: 800 } },
            color: { value: "#ffffff" },
            shape: { type: "circle", stroke: { width: 0, color: "#000000" } },
            opacity: { value: 0.5, random: false },
            size: { value: 3, random: true },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 6,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { enable: true, mode: "grab" },
              onclick: { enable: true, mode: "push" },
              resize: true,
            },
            modes: {
              grab: { distance: 140, line_linked: { opacity: 1 } },
              push: { particles_nb: 4 },
            },
          },
          retina_detect: true,
        });
      }
    };

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const variantsFromLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
    exit: { opacity: 0, x: -100, transition: { duration: 0.8 } },
  };

  const variantsFromRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
    exit: { opacity: 0, x: 100, transition: { duration: 0.8 } },
  };

  return (
    <>
    <div className="min-h-screen w-full overflow-x-hidden">

    <div className="relative z-50">
          <Navigation />
      </div>
      {/* Particles Container */}
      <div
        id="particles-js"
        className="fixed inset-0 pointer-events-none"
        style={{ zIndex: -1 }}
      />

      {/* Hero Section */}
      <div className="relative h-screen w-full flex items-center justify-center mb-20">
        <div className="text-center text-white px-4">
          <h1 className="text-4xl md:text-6xl font-bold text-shadow-lg mb-4">
            TN Home & Business
          </h1>
          <p className="text-xl md:text-2xl text-shadow">
            Soluciones Integrales de Seguridad para su hogar y negocio
          </p>
        </div>
      </div>

      {/* Content Sections */}
      <div className="bg-gray-100 py-20 px-4 md:px-5">
        <div className="max-w-7xl mx-auto space-y-20">
          {/* Why Choose Us Section */}
          <motion.div
            className="bg-white shadow-lg rounded-lg p-8 md:p-12 grid grid-cols-1 md:grid-cols-2 gap-10 items-center"
            initial="hidden"
            whileInView="visible"
            exit="exit"
            viewport={{ once: false, amount: 0.2 }}
          >
            <motion.div variants={variantsFromLeft} className="w-full">
              <img
                src={elegirnos}
                alt="¿Por qué elegirnos?"
                className="w-full h-auto rounded-lg object-cover"
                style={{ maxHeight: "500px" }}
              />
            </motion.div>
            <motion.div variants={variantsFromRight} className="flex flex-col justify-center">
              <h2 className="text-3xl md:text-4xl font-bold text-[#D32F2F] mb-6">
                ¿POR QUÉ ELEGIRNOS?
              </h2>
              <p className="text-base md:text-lg text-gray-700 leading-relaxed">
                En Tatningard Home & Business, estamos comprometidos con la
                excelencia y la innovación constante. Nuestro equipo de expertos
                se enfoca en brindar soluciones que no solo cumplen, sino que
                superan las expectativas de nuestros clientes.
              </p>
            </motion.div>
          </motion.div>

          {/* Our Values Section */}
          <motion.div
            className="bg-white shadow-lg rounded-lg p-8 md:p-12 grid grid-cols-1 md:grid-cols-2 gap-10 items-center"
            initial="hidden"
            whileInView="visible"
            exit="exit"
            viewport={{ once: false, amount: 0.2 }}
          >
            <motion.div variants={variantsFromLeft} className="flex flex-col justify-center">
              <h2 className="text-3xl md:text-4xl font-bold text-[#D32F2F] mb-6">
                NUESTROS VALORES
              </h2>
              <p className="text-base md:text-lg text-gray-700 leading-relaxed">
                En Tatningard Home & Business, nuestros valores fundamentales son
                el pilar de cada uno de nuestros servicios. Creemos en la
                integridad, la transparencia y el compromiso con la seguridad.
              </p>
            </motion.div>
            <motion.div variants={variantsFromRight} className="w-full">
              <img
                src={valores}
                alt="Nuestros Valores"
                className="w-full h-auto rounded-lg object-cover"
                style={{ maxHeight: "500px" }}
              />
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Interactive Circles Section */}
      <div className="w-full h-screen overflow-hidden">
        <CirclesDivision />
      </div>

      <Footer />
    </div>
    </>
  );
};

export default TnHome2;