// OperationsHeader.jsx
import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import "../../../styles/Tnstyles/Infraestructura.css";
import Navbar from "../../TnGroupComponents/NavbarTn";

const OperationsHeader = ({ divisionName = "Operaciones" }) => {
  return (
    <>
      {/* Navbar con posición fija */}
      <Box position="fixed" top="0" width="100%" zIndex="1000">
        <Navbar />
      </Box>

      {/* Añadir margen superior para compensar el Navbar fijo */}
      <Box mt="16">
        <div className="background-container">
          {/* Círculos animados de fondo */}
          <div className="circle xxlarge shade1"></div>
          <div className="circle xlarge shade2"></div>
          <div className="circle large shade3"></div>
          <div className="circle medium shade4"></div>
          <div className="circle small shade5"></div>

          {/* Título centrado */}
          <Flex
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            py={8}
          >
            <Heading as="h2" size="xl" color="white" fontWeight="bold">
              DIVISIÓN
            </Heading>
            <Heading as="h2" size="2xl" color="black" fontWeight="bold">
              {divisionName}
            </Heading>
          </Flex>
        </div>
      </Box>
    </>
  );
};

export default OperationsHeader;