import React from 'react';
import { motion } from 'framer-motion';

const UnderlineAnimation = ({ text, className = '' }) => {
  return (
    <motion.span className={`relative inline-block ${className}`}>
      {/* Texto animado */}
      <span className="font-semibold">{text}</span>

      {/* Línea animada */}
      <motion.div
        className="absolute bottom-0 left-0 w-full h-[3px] bg-red-500"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: [0, 1, 0] }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: 'loop',
          ease: 'easeInOut',
          repeatDelay: 2,
        }}
        style={{ transformOrigin: 'left center' }}
      />
    </motion.span>
  );
};

export default UnderlineAnimation;