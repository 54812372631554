import React from "react";
import {
  Card,
  CardBody,
  Text,
  Stack,
  Heading,
  Box,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import ImageCarousel from "../components/imagesCaruselInfra";
import Footer from "../components/Footer";

import infra from "../img/carruselinfra.jpg";
import fondo from "../img/infraestructurainfo.png";
import CardInfo from "../components/componetsDivision/CardInfo";
import Navigation from "../components/componentshomeandbusiness/Navigation";

const MotionHeading = motion(Heading);
const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionListItem = motion(ListItem);

const InfrastructureInfo = () => {
  const images = [infra];

  return (
    <div>
       <div className="relative z-50">
          <Navigation />
      </div>

      {/* Fondo principal */}
      <div className="bg-gradient-to-b from-gray-900 to-gray-800 min-h-screen">
        {/* Contenedor del título con imagen de fondo */}
        <Box
          position="relative"
          width="100%"
          height="700px"
          backgroundImage={`url(${fondo})`}
          backgroundSize="cover"
          backgroundPosition="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* Título animado */}
          <MotionHeading
            animate={{
              scale: [1.1, 1.2, 1.1],
              color: ["#FFFFFF", "#00FFFF", "#FF5A5F"],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: "mirror",
            }}
            fontSize={{ base: "3xl", md: "5xl", lg: "6xl" }}
            fontWeight="extrabold"
            textAlign="center"
            color="red.500"
            zIndex={1}
            lineHeight="shorter"
            mb={12}  // Más espacio debajo del título
          >
            Infraestructura
          </MotionHeading>

          {/* Sombra para mejor legibilidad */}
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.5)"
            zIndex={0}
          ></Box>
        </Box>

        {/* Texto introductorio */}
        <Box textAlign="center" mt={24} px={4}>
          <Text
            fontSize="2xl"
            color="gray.300"
            textAlign="center"
            maxW="3xl"
            mx="auto"
          >
            Proveemos <span className="font-bold text-red.500">soluciones integrales</span> en{" "}
            <span className="font-bold text-red.500">infraestructura</span>, desde{" "}
            <span className="font-bold text-red.500">sistemas de vigilancia</span> hasta{" "}
            <span className="font-bold text-red.500">tecnología innovadora</span> para proteger tus{" "}
            <span className="font-bold text-red.500">activos</span>.
          </Text>
        </Box>
        <Box mt={{ base: 32, md: 40 }}>

              <CardInfo/>
        </Box>
        <Box mt={24}>
          <Footer />
        </Box>
      </div>
    </div>
  );
};

export default InfrastructureInfo;