import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Shield, Home, Info, Dices as Services, Contact, Facebook, Linkedin, 
  ChevronDown, Menu, X, Lock, Terminal, MapPin
} from 'lucide-react';
import logo from '../../img/home/group.png';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();  // Hook para navegación

  const handleDropdownToggle = (menu) => {
    setActiveDropdown(activeDropdown === menu ? null : menu);
  };

  const handleLogoClick = () => {
    navigate('/');  // Navega a la ruta raíz
  };

  // Función para redirigir al hacer clic en "Home"
  const handleHomeClick = () => {
    navigate('/tn-group');  // Navega a la ruta /tn-group
  };

  const handleAboutClick = () => {
    navigate('/tn-about');
  };

  // Determina la ruta según el nombre del item
  const getServicesRoute = (item) => {
    switch (item) {
      case 'Ciberseguridad':
        return '/tn-cibersecurity-info';
      case 'Operaciones':
        return '/tn-operations-info';
      case 'Infraestructura':
        return '/tn-infraestructure-info';
      case 'Unidad de Riesgos Estrategicos':
        return '/tn-strategic-info';
      default:
        return '/default-url';
    }
  };

  const dropdownAnimation = {
    initial: { opacity: 0, y: -20, scale: 0.95 },
    animate: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      scale: 0.95,
      transition: {
        duration: 0.2,
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const dropdownItemAnimation = {
    initial: { opacity: 0, x: -20 },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 20,
      },
    },
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        duration: 0.2,
      },
    },
  };

  const glowAnimation = {
    animate: {
      boxShadow: [
        '0 0 0px rgba(239, 68, 68, 0)',
        '0 0 10px rgba(239, 68, 68, 0.3)',
        '0 0 0px rgba(239, 68, 68, 0)',
      ],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    },
  };

  const scanlineAnimation = {
    animate: {
      backgroundPosition: ['0 0', '0 -100%'],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        ease: 'linear',
      },
    },
  };

  const NavItem = ({ icon: Icon, text, onClick, hasDropdown = false }) => (
    <motion.button
      className="flex items-center text-gray-300 hover:text-red-500 relative group px-4 py-2"
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
    >
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-red-500/10 to-transparent opacity-0 group-hover:opacity-100 rounded"
        initial={false}
        animate={glowAnimation}
      />
      <Icon className="h-5 w-5 mr-2" />
      <span className="font-medium tracking-wide">{text}</span>
      {hasDropdown && (
        <ChevronDown 
          className={`h-4 w-4 ml-1 transform transition-transform ${activeDropdown === text.toLowerCase() ? 'rotate-180' : ''}`} 
        />
      )}
    </motion.button>
  );

  return (
    <motion.nav
      className="bg-black border-b border-red-500/30 fixed top-0 left-0 right-0"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 100, damping: 20 }}
      style={{ zIndex: 50 }}
    >
      <motion.div
        className="absolute inset-0 opacity-10 pointer-events-none"
        style={{
          backgroundImage: 'linear-gradient(transparent 0%, rgba(255,255,255,0.1) 50%, transparent 100%)',
          backgroundSize: '100% 8px',
        }}
        animate={scanlineAnimation}
      />
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-20 relative z-10">
          {/* Logo */}
          <motion.div 
            className="flex items-center space-x-3 cursor-pointer" 
            whileHover={{ scale: 1.05 }} 
            onClick={handleLogoClick}
          >
            <div className="relative">
              <motion.div
                className="absolute inset-0 bg-red-500 rounded-full blur-md"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.5, 0.8, 0.5],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              />
              <img src={logo} alt="Logo" className="h-350 w-20 relative" />
            </div>
            <div className="flex flex-col">
              {/* Opcional: texto debajo o al lado del logo */}
            </div>
          </motion.div>

          {/* Desktop Navigation */}
          <motion.div 
            className="hidden md:flex items-center space-x-1" 
            initial="initial" 
            animate="animate"
          >
            <NavItem icon={Home} text="Home" onClick={handleHomeClick} />
            <NavItem icon={Lock} text="About" onClick={handleAboutClick} />

            {/* Services Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => setActiveDropdown('services')}
              onMouseLeave={() => setActiveDropdown(null)}
            >
              <NavItem 
                icon={Services} 
                text="Services" 
                hasDropdown 
                onClick={() => handleDropdownToggle('services')} 
              />

              <AnimatePresence>
                {activeDropdown === 'services' && (
                  <motion.div
                    className="absolute right-0 mt-2 w-56 bg-gray-800 rounded-md shadow-lg py-1 z-50 border border-red-500/30"
                    variants={dropdownAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    {['Ciberseguridad', 'Operaciones', 'Infraestructura', 'Unidad de Riesgos Estrategicos'].map((item) => {
                      const isStrategic = item === 'Unidad de Riesgos Estrategicos';
                      return (
                        <motion.a
                          key={item}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(getServicesRoute(item));
                          }}
                          className={`block px-4 py-2 text-sm ${
                            isStrategic 
                              ? 'text-[#C0C0C0] font-bold'   // <--- Se reemplaza el dorado por plateado
                              : 'text-gray-300'
                          } hover:bg-red-500/10 hover:text-red-500 group relative`}
                          variants={dropdownItemAnimation}
                        >
                          {/* Si es "Unidad de Riesgos Estrategicos", se le añade el gradiente plateado */}
                          {isStrategic && (
                            <motion.div
                              className="absolute inset-0 pointer-events-none rounded"
                             
                              transition={{
                                duration: 2,
                                repeat: Infinity,
                                ease: 'easeInOut'
                              }}
                            />
                          )}
                          <motion.span
                            className="absolute left-0 top-1/2 w-1 h-0 bg-red-500"
                            initial={{ height: 0 }}
                            whileHover={{ height: '60%', y: '-50%' }}
                            transition={{ duration: 0.2 }}
                          />
                          {item}
                        </motion.a>
                      );
                    })}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {/* Contact Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => setActiveDropdown('contact')}
              onMouseLeave={() => setActiveDropdown(null)}
            >
              <NavItem 
                icon={Contact} 
                text="Contact" 
                hasDropdown 
                onClick={() => handleDropdownToggle('contact')} 
              />

              <AnimatePresence>
                {activeDropdown === 'contact' && (
                  <motion.div
                    className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg py-1 z-50 border border-red-500/30"
                    variants={dropdownAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <motion.a
                      href="https://www.facebook.com/tatningard"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-red-500/10 hover:text-red-500 group"
                      variants={dropdownItemAnimation}
                    >
                      <Facebook className="h-4 w-4 mr-2" />
                      Facebook
                    </motion.a>
                    <motion.a
                      href="https://www.linkedin.com/company/tatningard-group/posts/?feedView=all"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-red-500/10 hover:text-red-500 group"
                      variants={dropdownItemAnimation}
                    >
                      <Linkedin className="h-4 w-4 mr-2" />
                      LinkedIn
                    </motion.a>
                    <motion.a
                      href="https://www.google.com/maps/search/tatningard/@19.410729,-99.1682125,10z?entry=ttu&g_ep=EgoyMDIxMi4wIKXMDSoJLDEwMjExMjMzSAFQAw%3D%3D"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-red-500/10 hover:text-red-500 group"
                      variants={dropdownItemAnimation}
                    >
                      <MapPin className="h-4 w-4 mr-2" />
                      Mapa
                    </motion.a>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>

          {/* Mobile Menu Button */}
          <motion.button
            className="md:hidden text-gray-300 hover:text-red-500"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            whileHover={{ scale: 1.1 }}
          >
            {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </motion.button>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen bg-gray-900 z-[100] md:hidden overflow-y-auto"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <div className="flex justify-end p-4">
              <button 
                onClick={() => setIsMobileMenuOpen(false)}
                className="text-gray-300 hover:text-red-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <motion.div className="p-4 space-y-4">
              <motion.a 
                href="#" 
                className="flex items-center text-gray-300 hover:text-red-500 p-2 group" 
                whileHover={{ x: 10 }}
                onClick={() => navigate('/tn-group')}
              >
                <Home className="h-5 w-5 mr-2" />
                Home
              </motion.a>
              <motion.a 
                href="#" 
                className="flex items-center text-gray-300 hover:text-red-500 p-2 group" 
                whileHover={{ x: 10 }}
                onClick={() => navigate('/tn-about')}
              >
                <Lock className="h-5 w-5 mr-2" />
                About
              </motion.a>

              {/* Mobile Services */}
              <div className="space-y-2">
                <motion.button
                  className="flex items-center text-gray-300 hover:text-red-500 p-2 w-full group"
                  onClick={() => handleDropdownToggle('mobile-services')}
                  whileHover={{ x: 10 }}
                >
                  <Services className="h-5 w-5 mr-2" />
                  Services
                  <ChevronDown 
                    className={`h-4 w-4 ml-auto transform transition-transform ${
                      activeDropdown === 'mobile-services' ? 'rotate-180' : ''
                    }`} 
                  />
                </motion.button>
                <AnimatePresence>
                  {activeDropdown === 'mobile-services' && (
                    <motion.div 
                      className="pl-8 space-y-2" 
                      variants={dropdownAnimation} 
                      initial="initial" 
                      animate="animate" 
                      exit="exit"
                    >
                      {['Ciberseguridad', 'Operaciones', 'Infraestructura', 'Unidad de Riesgos Estrategicos'].map((item) => {
                        const isStrategic = item === 'Unidad de Riesgos Estrategicos';
                        return (
                          <motion.a
                            key={item}
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(getServicesRoute(item));
                            }}
                            className={`block text-gray-400 hover:text-red-500 p-2 relative group ${
                              isStrategic ? 'text-[#C0C0C0] font-bold' : ''
                            }`}
                            variants={dropdownItemAnimation}
                            whileHover={{ x: 10 }}
                          >
                            {isStrategic && (
                              <motion.div
                                className="absolute inset-0 pointer-events-none rounded"
                              
                                transition={{
                                  duration: 2,
                                  repeat: Infinity,
                                  ease: 'easeInOut'
                                }}
                              />
                            )}
                            <motion.span
                              className="absolute left-0 top-1/2 w-1 h-0 bg-red-500"
                              initial={{ height: 0 }}
                              whileHover={{ height: '60%', y: '-50%' }}
                              transition={{ duration: 0.2 }}
                            />
                            {item}
                          </motion.a>
                        );
                      })}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Mobile Contact */}
              <div className="space-y-2">
                <motion.button
                  className="flex items-center text-gray-300 hover:text-red-500 p-2 w-full group"
                  onClick={() => handleDropdownToggle('mobile-contact')}
                  whileHover={{ x: 10 }}
                >
                  <Contact className="h-5 w-5 mr-2" />
                  Contact
                  <ChevronDown 
                    className={`h-4 w-4 ml-auto transform transition-transform ${
                      activeDropdown === 'mobile-contact' ? 'rotate-180' : ''
                    }`} 
                  />
                </motion.button>
                <AnimatePresence>
                  {activeDropdown === 'mobile-contact' && (
                    <motion.div 
                      className="pl-8 space-y-2" 
                      variants={dropdownAnimation} 
                      initial="initial" 
                      animate="animate" 
                      exit="exit"
                    >
                      <motion.a
                        href="https://www.facebook.com/tatningard"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-gray-400 hover:text-red-500 p-2"
                        variants={dropdownItemAnimation}
                        whileHover={{ x: 10 }}
                      >
                        <Facebook className="h-4 w-4 mr-2" />
                        Facebook
                      </motion.a>
                      <motion.a
                        href="https://www.linkedin.com/company/tatningard-group/posts/?feedView=all"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-gray-400 hover:text-red-500 p-2"
                        variants={dropdownItemAnimation}
                        whileHover={{ x: 10 }}
                      >
                        <Linkedin className="h-4 w-4 mr-2" />
                        LinkedIn
                      </motion.a>
                      <motion.a
                        href="https://www.google.com/maps/search/tatningard/@19.410729,-99.1682125,10z?entry=ttu&g_ep=EgoyMDIxMi4wIKXMDSoJLDEwMjExMjMzSAFQAw%3D%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-gray-400 hover:text-red-500 p-2"
                        variants={dropdownItemAnimation}
                        whileHover={{ x: 10 }}
                      >
                        <MapPin className="h-4 w-4 mr-2" />
                        Mapa
                      </motion.a>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default Navbar;