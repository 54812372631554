import React from 'react';
import Navbar from '../../components/TnGroupComponents/NavbarTn';
import Backgroud from '../../components/TnGroupComponents/Backgroud';
import CardHow from '../../components/TnGroupComponents/CardHow';
import CardWho from '../../components/TnGroupComponents/CardWho';
import CircularDivision from '../../components/TnGroupComponents/CircularDivision';
import Footer from '../../components/TnGroupComponents/Footer';

const TnPageNew = () => {
  return (
    <div className="relative bg-gray-900 text-white">
      {/* Navbar */}
      <div className="fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>

      {/* Contenido principal */}
      <div className=" min-h-screen">
        {/* Fondo */}
        <div>
          <Backgroud />
        </div>

        <div className="container mx-auto px-4 py-8 space-y-32">
          <div>
            <CardHow />
          </div>
          <div>
            <CardWho />
          </div>

          <div>
            <CircularDivision/>
          </div>


              
        </div>
      </div>

      {/* Footer */}
      <div>
        
            <Footer/>
          
      </div>
    </div>
  );
};

export default TnPageNew;