import { useState, useEffect, useCallback } from "react";
import { FaChevronLeft, FaChevronRight, FaPause, FaPlay } from "react-icons/fa";
import { motion } from "framer-motion";  
import { LettersPullUp } from "../../components/motion/LettersPullUp";  
import { TypingEffect } from "../../components/motion/TypingEffect";  

import antivirus1 from "../../img/carruselseguridad/Antivirus.png";
import antivirus2 from "../../img/carruselseguridad/Antivirus2.png";

const MotionBox = motion.div;

const CardCiber = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const images = [
    { url: antivirus1, alt: "Antivirus protection setup" },
    { url: antivirus2, alt: "Advanced cybersecurity measures" },
  ];

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }, [images.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => nextSlide(), 4000);
    }
    return () => clearInterval(intervalId);
  }, [isPlaying, nextSlide]);

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="bg-white rounded-xl shadow-2xl overflow-hidden md:grid md:grid-cols-2">
        
        {/* Text Section con animación */}
        <MotionBox
          className="p-8 md:p-12 flex flex-col justify-center"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.8 }}
        >
          {/* Título animado con LettersPullUp */}
          <h2 className="text-4xl font-bold mb-6 text-gray-800 tracking-tight">
            <LettersPullUp text="Protección con Antivirus" />
          </h2>

          {/* Descripción con palabra clave animada */}
          <p className="text-gray-600 text-lg mb-8 leading-relaxed">
            Mantén tu sistema protegido con una{" "}
            <TypingEffect text="solución avanzada" className="font-bold text-red-500" />{" "}
            que cubre todas las áreas críticas de seguridad digital.
          </p>

          <ul className="text-gray-700 text-lg mb-8 leading-relaxed list-disc pl-6 space-y-2">
            <li>
              <span className="font-semibold">Protección completa:</span> Antivirus, antimalware y defensa anti-ransomware.
            </li>
            <li>
              <span className="font-semibold">Navegación segura:</span> Protección web, eliminación de virus y bloqueo de amenazas en tiempo real.
            </li>
            <li>
              <span className="font-semibold">Seguridad contra ataques:</span> Protección avanzada contra phishing y malware.
            </li>
            <li>
              <span className="font-semibold">Firewall inteligente:</span> Administración de aplicaciones y control de accesos.
            </li>
            <li>
              <span className="font-semibold">Optimización del sistema:</span> Limpieza, supervisión y rendimiento de dispositivos.
            </li>
            <li>
              <span className="font-semibold">Protección financiera:</span> Seguridad en pagos y gestión segura de contraseñas.
            </li>
            <li>
              <span className="font-semibold">VPN ilimitada:</span> Protección de identidades y conexiones en línea.
            </li>
            <li>
              <span className="font-semibold">Soporte experto:</span> Asistencia remota y eliminación de virus por profesionales.
            </li>
          </ul>
        </MotionBox>

        {/* Image Carousel Section */}
        <div className="relative h-[400px] md:h-full" tabIndex="0">
          {images.map((image, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-1000 ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
            >
              <img
                src={image.url}
                alt={image.alt}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </div>
          ))}

          {/* Carousel Controls */}
          <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-4">
            <button
              onClick={prevSlide}
              className="p-2 bg-black/50 text-white rounded-full hover:bg-black/70 transition"
              aria-label="Previous slide"
            >
              <FaChevronLeft />
            </button>

            <button
              onClick={() => setIsPlaying(!isPlaying)}
              className="p-2 bg-black/50 text-white rounded-full hover:bg-black/70 transition"
              aria-label={isPlaying ? "Pause slideshow" : "Play slideshow"}
            >
              {isPlaying ? <FaPause /> : <FaPlay />}
            </button>

            <button
              onClick={nextSlide}
              className="p-2 bg-black/50 text-white rounded-full hover:bg-black/70 transition"
              aria-label="Next slide"
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCiber;