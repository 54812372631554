// Operations.jsx
import React from "react";
import OperationsHeader from "../../components/TnGroupComponents/Division/OperationsHeader";
import Footer from '../../components/TnGroupComponents/Footer'
import CiberContent from "../../components/TnGroupComponents/Division/CiberContent";
const Cybersecurity = () => {
  return (
    <>
       <OperationsHeader divisionName="Ciberseguridad" />
        <CiberContent />
      <Footer/>
    </>
  );
};

export default Cybersecurity;