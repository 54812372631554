// InfraContent.jsx
import React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

// Importa tus componentes reutilizables
import WhatWeDoSection from "../WhatWeDoSection";
import ReusableCard from "../ReusableCard";

// Importa tus imágenes
import infraIMG from '../../../img/pagetatnin/infraprincipal.jpeg'
import EsquemaInfra from "./EsquemaInfra";

// Configuración de las animaciones
const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.5,
    },
  },
};

const InfraContent = ({
  // Props opcionales para modificar la sección "¿Qué hacemos?"
  whatWeDoImage = infraIMG,
  whatWeDoTitle = "¿Qué hacemos?",
  whatWeDoDescription = `Diseñamos y construimos proyectos de infraestructura segura, creando entornos físicos con altos estándares de seguridad. 
Implementamos equipos tecnológicos con prestaciones específicas que mejoran el desarrollo de las operaciones críticas de cada cliente. 
Nuestro servicio está enfocado en el cumplimiento de objetivos y tiempos de entrega.`
}) => {
  // Ajustamos las 3 columnas para que coincidan con tu imagen de referencia
  const soluciones = [
    {
      title: "CORPORATIVAS",
      items: [
        "Control de acceso.",
        "Detector de metales, escáner milimétrico y rayos X.",
        "Videovigilancia y reconocimiento facial.",
        "Amplificador de señal 4G-LTE / 3G.",
        "Contraseña corporativa."
      ],
    },
    {
      title: "INDUSTRIALES",
      items: [
        "Mantenimiento, obra civil y estructuras metálicas.",
        "Equipamiento para naves industriales.",
        "Barreras físicas (Certificación antiterrorismo).",
        "Radiocomunicación.",
        "Sistema de ventilación HVAC y purificación de aire."
      ],
    },
    {
      title: "RESIDENCIALES",
      items: [
        "Proyecto arquitectónico y obra civil.",
        "Domótica (Casas inteligentes).",
        "Automatización de accesos.",
        "Perímetros de seguridad.",
        "Cuartos y puertas de seguridad.",
        "Construcción sustentable y energías limpias."
      ],
    },
  ];

  return (
    <>
      {/* Sección "¿Qué hacemos?" */}
      <div className="flex items-center justify-center p-6 bg-gray-800">
        <WhatWeDoSection
          image={whatWeDoImage}
          title={whatWeDoTitle}
          description={whatWeDoDescription}
          altText="Operaciones"
        />
      </div>

      {/* Sección Diagrama */}
      <div className="p-6 text-center bg-gray-400">
        <Heading as="h3" size="2xl" mb={6} color="white">
        Metodología de<br /> Trabajo
        </Heading>
        <Flex justify="center" align="center">
          <EsquemaInfra/>
        </Flex>
      </div>

      {/* Sección de Soluciones (cartas centradas y proporcionadas con Grid) */}
      <div className="p-6 text-center bg-gray-800">
        <h3 className="text-3xl font-semibold mb-6 text-white">SOLUCIONES</h3>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-6 items-stretch max-w-6xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {soluciones.map((solucion, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              className="flex flex-col h-full"
            >
              <ReusableCard title={solucion.title} items={solucion.items} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default InfraContent;


