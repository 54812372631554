import React from 'react'
import BuildingOff from '../components/componetsHome/home-building/BuildingOff'
import HomeOff from '../components/componetsHome/home-home/HomeOff'


const NewHome2 = () => {
  return (
    <>
      
<div className="grid grid-cols-6 grid-rows-6 gap-0">
    <div className="col-span-3 row-span-6">

        <BuildingOff/>

    </div>
    <div className="col-span-3 row-span-6 col-start-4">
        <HomeOff/>
    </div>
</div>
    
    </>
  )
}

export default NewHome2
