import React from 'react';
import CircularGallery from './CircularGallery';
import Magnet from '../../components/motion/Magnet';
import { useNavigate } from 'react-router-dom';



const CircularDivision = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col items-center justify-center text-white mb-10 space-y-6">
        {/* Título estilizado */}
        <h1 className="mb-4 text-4xl font-extrabold tracking-wide text-center">
          Nuestras divisiones
        </h1>

        {/* Botón estilizado con Magnet */}
        <Magnet padding={400} disabled={false} magnetStrength={2}>
        <button
        className="px-6 py-3 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg shadow-md 
                   focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 transition-all"
        onClick={() => navigate('/tn-strategic-info')}
      >
        Mas información
      </button>
        </Magnet>
      </div>

      <div style={{ height: '600px', position: 'relative', marginTop: '30px' }}>
        <CircularGallery bend={3} textColor="#ffffff" borderRadius={0.05} />
      </div>
    </>
  );
};

export default CircularDivision;