// ReusableCard.jsx
import React from "react";
import { Box, Heading } from "@chakra-ui/react";

const ReusableCard = ({ title, items }) => {
  return (
    <Box
      bgGradient="linear(to-r, gray.700, gray.800)"
      color="white"
      p={6}
      borderRadius="md"
      boxShadow="lg"
      className="transition-transform duration-300 transform hover:scale-105 flex flex-col justify-between h-full"
      position="relative"
    >
      <Heading
        as="h4"
        size="lg"
        mb={4}
        textAlign="left"
        borderBottom="2px solid"
        borderColor="red.400"
        pb={2}
      >
        {title}
      </Heading>
      <ul className="text-gray-300 space-y-2 text-lg flex-grow">
        {items.map((item, idx) => (
          <li key={idx}>• {item}</li>
        ))}
      </ul>
      <Box
        position="absolute"
        top="-10px"
        left="-10px"
        bg="red.400"
        w="8px"
        h="8px"
        borderRadius="full"
      />
      <Box
        position="absolute"
        bottom="-10px"
        right="-10px"
        bg="red.400"
        w="8px"
        h="8px"
        borderRadius="full"
      />
    </Box>
  );
};

export default ReusableCard;