import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import casaImage from '../../../img/home/Casa.png'; 
import fondocasaon from './fondocasaon.png';
import casaOn from '../../../img/home/casaOn.png';
import logohome from '../../../img/home/homeandbusiness.png';

const HomeOff = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  // Actualiza el estado isMobile al cambiar el tamaño de pantalla
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleHomeClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      navigate('/tn-home');
    }, 1000);
  };

  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Fondo borroso */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundImage: `url(${fondocasaon})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(5px)',
          zIndex: -1,
        }}
      ></div>

      {/* Logo con fondo circular */}
      <div
        style={{
          position: 'absolute',
          top: isMobile ? '240px' : '2px',
          zIndex: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: isMobile ? '150px' : '300px',
          height: isMobile ? '150px' : '300px',
          backdropFilter: 'blur(5px)',
        }}
      >
        <img
          src={logohome}
          alt="Logo"
          style={{
            width: isMobile ? '100px' : '200px',
            animation: 'float 3s ease-in-out infinite',
          }}
          onClick={handleHomeClick}
        />
      </div>

      {/* Imagen de la casa */}
      <img
        src={isHovered || isClicked ? casaOn : casaImage}
        alt="Home"
        style={{
          position: 'relative',
          zIndex: 1,
          transition: 'transform 0.5s ease-in-out',
          transform: isClicked
            ? 'scale(2) translateY(-50px)'
            : isHovered
            ? 'scale(1.5)'
            : 'scale(1.2)',
          cursor: 'pointer',
          width: isMobile ? '300px' : '550px',
          height: 'auto',
          marginTop: isMobile ? '350px' : '400px',
        }}
        onClick={handleHomeClick}
      />

      {/* CSS Inline para animación */}
      <style>
        {`
          @keyframes float {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-20px);
            }
            100% {
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default HomeOff;