import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Telescope, ArrowRight, Rocket, Target, Lightbulb } from 'lucide-react';
import vision from '../../img/pagetatnin/visiontatnin.jpeg'


const features = [
  { icon: Rocket, text: "Liderazgo Tecnológico", delay: 0.7 },
  { icon: Target, text: "Impacto Global", delay: 0.8 },
  { icon: Lightbulb, text: "Innovación Sostenible", delay: 0.9 },
];

const textVariants = {
  hidden: { opacity: 0, y: 75 },
  visible: { opacity: 1, y: 0 },
};

function FeatureItem({ icon: Icon, text, delay, isInView }) {
  return (
    <motion.div
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={textVariants}
      transition={{ delay }}
      className="flex items-center gap-4 group cursor-pointer"
    >
      <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-gradient-to-br from-gray-50 to-red-50 group-hover:from-gray-100 group-hover:to-red-100 transition-colors duration-300">
        <Icon className="w-6 h-6 text-red-600 group-hover:scale-110 transition-transform duration-300" />
      </div>
      <div className="flex items-center gap-2">
        <span className="text-gray-900 font-medium group-hover:text-red-600 transition-colors duration-300">
          {text}
        </span>
        <ArrowRight className="w-4 h-4 text-gray-400 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300" />
      </div>
    </motion.div>
  );
}

function Vision() {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: "-100px" });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="w-full max-w-6xl bg-gradient-to-br from-white to-gray-50 rounded-3xl shadow-2xl overflow-hidden border border-gray-200 mt-8"
    >
      <div className="flex flex-col md:flex-row">
        <ContentSection isInView={isInView} />
        <ImageSection isInView={isInView} />
      </div>
    </motion.div>
  );
}

function ContentSection({ isInView }) {
  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0.5, duration: 0.8 }}
      className="md:w-1/2 p-8 md:p-12 order-2 md:order-1"
    >
      <motion.div
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={textVariants}
        transition={{ delay: 0.2, duration: 0.8 }}
        className="flex items-center gap-3 mb-8"
      >
        <div className="relative">
          <div className="absolute -inset-1 bg-gradient-to-r from-red-500 to-gray-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
          <Telescope className="w-10 h-10 text-red-600 relative" />
        </div>
        <h2 className="text-4xl font-bold bg-gradient-to-r from-red-600 to-gray-900 bg-clip-text text-transparent">
          Nuestra Visión
        </h2>
      </motion.div>

      <motion.p
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={textVariants}
        transition={{ delay: 0.4, duration: 0.8 }}
        className="text-lg text-gray-800 leading-relaxed mb-8 font-medium"
      >
        Ser la organización líder en Latinoamérica en seguridad empresarial, con un enfoque estratégico que se adapta a las necesidades de cada cliente, mitigando riesgos y preparándolos para circunstancias emergentes. Nos comprometemos a enriquecer a nuestra comunidad mediante herramientas y entrenamientos multidisciplinarios en seguridad.
      </motion.p>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ delay: 0.6, duration: 0.8 }}
        className="space-y-4"
      >
        {features.map((item, index) => (
          <FeatureItem key={index} {...item} isInView={isInView} />
        ))}
      </motion.div>
    </motion.div>
  );
}

function ImageSection({ isInView }) {
  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0.3, duration: 0.8 }}
      className="md:w-1/2 relative group order-1 md:order-2"
    >
      <div className="absolute inset-0 bg-gradient-to-l from-red-500/20 to-gray-500/20 mix-blend-multiply transition-opacity group-hover:opacity-75" />
      <img
        src={vision}
        alt="Future vision"
        className="w-full h-80 md:h-full object-cover transition-transform duration-700 group-hover:scale-105"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: 0.5 }}
        className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6 md:p-8"
      >
        <p className="text-white text-sm md:text-base font-medium">
          Construyendo el mañana, hoy
        </p>
      </motion.div>
    </motion.div>
  );
}

export default Vision;