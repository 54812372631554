import React from 'react';
import { motion } from 'framer-motion';
import missionImg from '../../img/misionNew.png';
import visionImg from '../../img/visionNew.png';

// Subrayado animado
import UnderlineAnimation from '../motion/UnderlineAnimation';
// Animación de letras del título
import { LettersPullUp } from '../motion/LettersPullUp';

const MissionVision = () => {
  const scrollVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  const imageAnimation = {
    hidden: { opacity: 0, rotate: -5, scale: 0.9 },
    visible: { opacity: 1, rotate: [0, 3, -3, 0], scale: [1, 1.05, 1] },
  };

  return (
    <div className="bg-gray-100 py-16">
      {/* Misión */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        exit="hidden"
        viewport={{ once: false, amount: 0.2 }}
        transition={{ duration: 0.8 }}
        variants={scrollVariant}
        className="grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-4 md:px-20 py-10"
      >
        {/* Texto Misión */}
        <motion.div
          className="text-center md:text-left"
          transition={{ duration: 0.8 }}
          variants={scrollVariant}
        >
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
            <LettersPullUp text="Misión" />
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Nuestra misión es ofrecer soluciones de{' '}
            <UnderlineAnimation text="seguridad innovadora" className="text-red-500 font-medium" /> y de alta calidad,
            brindando tranquilidad y protección tanto en hogares como en negocios.
          </p>
        </motion.div>

        {/* Imagen Misión con animación */}
        <motion.img
          src={missionImg}
          alt="Misión"
          className="w-full h-auto rounded-lg shadow-lg"
          initial={{ opacity: 0, rotate: -5 }}
          whileInView={{ opacity: 1, rotate: 0 }}
          viewport={{ once: false, amount: 0.2 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          variants={imageAnimation}
          animate={{ rotate: [0, 3, -3, 0], scale: [1, 1.05, 1] }}
          transition={{
            duration: 6,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
          }}
        />
      </motion.div>

      {/* Visión */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        exit="hidden"
        viewport={{ once: false, amount: 0.2 }}
        transition={{ duration: 0.8 }}
        variants={scrollVariant}
        className="grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-4 md:px-20 py-10"
      >
        {/* Imagen Visión con animación */}
        <motion.img
          src={visionImg}
          alt="Visión"
          className="w-full h-auto rounded-lg shadow-lg order-last md:order-first"
          initial={{ opacity: 0, rotate: 5 }}
          whileInView={{ opacity: 1, rotate: 0 }}
          viewport={{ once: false, amount: 0.2 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          variants={imageAnimation}
          animate={{ rotate: [0, -3, 3, 0], scale: [1, 1.05, 1] }}
          transition={{
            duration: 6,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
          }}
        />

        {/* Texto Visión */}
        <motion.div
          className="text-center md:text-left"
          transition={{ duration: 0.8 }}
          variants={scrollVariant}
        >
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
            <LettersPullUp text="Visión" />
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Nos visualizamos como la empresa{' '}
            <UnderlineAnimation text="líder en seguridad" className="text-red-500 font-medium" />, reconocida a nivel nacional por
            nuestra excelencia en servicios y soluciones innovadoras.
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default MissionVision;