import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

import fondo from "../img/fondooperacionesNew.png";
import Navbar from "../components/Navbar";
import CardsOp from "../components/componetsDivision/CardsOpe";
import Footer from "../components/Footer";
import Navigation from "../components/componentshomeandbusiness/Navigation";

const MotionHeading = motion(Text);

const OperationInfo = () => {
  return (
    <div>
       <div className="relative z-50">
          <Navigation />
      </div>

      {/* Fondo principal */}
      <div className="bg-gradient-to-b from-gray-900 to-gray-800 min-h-screen">
        <Box
          position="relative"
          width="100%"
          height="700px"
          backgroundImage={`url(${fondo})`}
          backgroundSize="cover"
          backgroundPosition="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* Título animado */}
          <MotionHeading
            animate={{
              scale: [1.1, 1.2, 1.1],
              color: ["#FFFFFF", "#FF4D4D", "#FF0000"],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: "mirror",
            }}
            fontSize={{ base: "3xl", md: "5xl", lg: "6xl" }}
            fontWeight="extrabold"
            textAlign="center"
            color="red.500"
            zIndex={1}
            lineHeight="shorter"
            mb={12}
          >
            Operaciones
          </MotionHeading>

          {/* Sombra para mejor legibilidad */}
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.5)"
            zIndex={0}
          />
        </Box>

        {/* Texto introductorio */}
        <Box textAlign="center" mt={24} px={4}>
          <Text fontSize="2xl" color="gray.300" textAlign="center" maxW="3xl" mx="auto">
          Ofrecemos cursos diseñados para desarrollar habilidades esenciales en diversas áreas clave, combinando teoría y práctica.
          </Text>
        </Box>

        {/* Tarjetas de cursos */}
        <Box mt={{ base: 32, md: 40 }}>
          <CardsOp />
        </Box>

        <Box mt={24}>
          <Footer />
        </Box>
      </div>
    </div>
  );
};

export default OperationInfo;