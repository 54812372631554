import React from 'react';

const EsquemaOpera = () => {
  const styles = `
    .esquema-opera__wrapper {
      font-family: sans-serif;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      margin: 0;
      padding: 20px;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
    }
    .esquema-opera__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1200px;
      position: relative;
      margin: 0 auto;
      z-index: 1;
    }
    .esquema-opera__title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 3rem;
      text-align: center;
      width: 100%;
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    }
    .esquema-opera__content-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .esquema-opera__diagram-container {
      position: relative;
      width: 500px;
      height: 500px;
      margin-left: 50px;
      order: 2;
    }
    .esquema-opera__circle {
      border-radius: 50%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      animation: esquema-opera-breathe 3s ease-in-out infinite alternate;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
    }
    @keyframes esquema-opera-breathe {
      0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
      }
      100% {
        transform: scale(1.05);
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.6);
      }
    }
    .esquema-opera__outer {
      width: 100%;
      height: 100%;
      border: 2px solid #9E9E9E;
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
      animation-delay: 0s;
    }
    .esquema-opera__middle {
      width: 70%;
      height: 70%;
      background-color: #333;
      top: 15%;
      left: 15%;
      animation-delay: 0.2s;
      position: absolute;
    }
    .esquema-opera__inner {
      width: 40%;
      height: 40%;
      background-color: #555;
      top: 30%;
      left: 30%;
      animation-delay: 0.4s;
      position: absolute;
    }
    .esquema-opera__core {
      width: 25%;
      height: 25%;
      background-color: #222;
      color: white;
      font-size: 1rem;
      top: 37.5%;
      left: 37.5%;
      text-align: center;
      text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
      animation-delay: 0.6s;
      position: absolute;
    }
    .esquema-opera__labels-container {
      position: relative;
      width: 300px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      order: 1;
      z-index: 2;
    }
    .esquema-opera__label {
      position: relative;
      width: 220px;
      text-align: right;
      color: #ccc;
      font-size: 1rem;
      margin-bottom: 40px;
      opacity: 0;
      animation: esquema-opera-fadeIn 2s ease-out forwards;
      cursor: pointer;
      transition: color 0.3s ease, transform 0.3s ease;
    }
    .esquema-opera__label-outer {
      animation-delay: 1s;
    }
    .esquema-opera__label-middle {
      animation-delay: 1.5s;
    }
    .esquema-opera__label-inner {
      animation-delay: 2s;
    }
    @keyframes esquema-opera-fadeIn {
      from {
        opacity: 0;
        transform: translateX(-20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    .esquema-opera__label:hover,
    .esquema-opera__label:focus {
      color: white;
      transform: translateX(-5px);
      outline: none;
    }
    .esquema-opera__tooltip {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      display: none;
      z-index: 3;
      white-space: nowrap;
      pointer-events: none;
    }
    /* Adaptabilidad en Celulares */
    @media (max-width: 768px) {
      .esquema-opera__content-wrapper {
        flex-direction: column;
      }
      .esquema-opera__diagram-container {
        width: 300px;
        height: 300px;
        margin-left: 0;
        order: 2;
      }
      .esquema-opera__labels-container {
        width: 220px;
        height: auto;
        margin-bottom: 20px;
        order: 1;
      }
      .esquema-opera__label {
        font-size: 0.9rem;
      }
    }

    /* Aquí definimos la clase "bg-whites" para que el HR sea rojo */
    hr.bg-whites {
      background-color: red; /* Color rojo */
      border: none;
      height: 2px; /* Ajusta el grosor de la línea */
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="esquema-opera__wrapper">
        <div className="esquema-opera__container">
          <div className="esquema-opera__title">
            ATMÓSFERA DE<br />SEGURIDAD GENERAL
          </div>
          <div className="esquema-opera__content-wrapper">
            <div className="esquema-opera__labels-container">
              <div
                className="esquema-opera__label esquema-opera__label-outer"
                data-tooltip="Información sobre la barrera perimetral"
              >
                BARRERA PERIMETRAL
                <hr className="bg-whites" style={{ width: '180%', marginLeft: 'auto' }} />
              </div>

              <div
                className="esquema-opera__label esquema-opera__label-middle"
                data-tooltip="Información sobre la zona de seguridad exterior"
              >
                ZONA DE SEGURIDAD EXTERIOR
                <hr className="bg-whites" style={{ width: '200%', marginLeft: 'auto' }} />
              </div>

              <div
                className="esquema-opera__label esquema-opera__label-inner"
                data-tooltip="Información sobre la zona de seguridad interior"
              >
                ZONA DE SEGURIDAD INTERIOR
                <hr className="bg-whites" style={{ width: '260%', marginLeft: 'auto' }} />
              </div>
            </div>

            <div className="esquema-opera__diagram-container">
              <div
                className="esquema-opera__circle esquema-opera__outer"
                data-tooltip="Información sobre el círculo exterior"
              />
              <div
                className="esquema-opera__circle esquema-opera__middle"
                data-tooltip="Información sobre el círculo intermedio"
              />
              <div
                className="esquema-opera__circle esquema-opera__inner"
                data-tooltip="Información sobre el círculo interior"
              />
              <div
                className="esquema-opera__circle esquema-opera__core"
                data-tooltip="Información sobre el núcleo de protección"
              >
                PROTECCIÓN<br />DE ACTIVO (S)
              </div>
            </div>
          </div>
        </div>
        <div className="esquema-opera__tooltip"></div>
      </div>
    </>
  );
};

export default EsquemaOpera;