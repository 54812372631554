import React from 'react';
import Back from '../components/about-componets/background/Back';
import Title from '../components/about-componets/Title';
import Navigation from '../components/componentshomeandbusiness/Navigation'

import Footer from '../components/Footer';
import InfoAbout from '../components/about-componets/InfoAbout';

const AboutNew = () => {
  return (
    <>
     <div className="relative z-50">
          <Navigation />
      </div>
    <div className="relative w-full h-screen grid place-items-center text-center bg-black">
      <Back />
      <Title/>
    </div>

<InfoAbout/>
    <Footer/>
    </>
  );
};

export default AboutNew;