// Operations.jsx
import React from "react";
import OperationsHeader from "../../components/TnGroupComponents/Division/OperationsHeader";
import OperationsContent from "../../components/TnGroupComponents/Division/OperationsContent";
import Footer from '../../components/TnGroupComponents/Footer'
const Operations = () => {
  return (
    <>
       <OperationsHeader divisionName="Operaciones" />
      <OperationsContent />
      <Footer/>
    </>
  );
};

export default Operations;