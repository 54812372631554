import { useState, useEffect, useCallback } from "react";
import { FaChevronLeft, FaChevronRight, FaPause, FaPlay } from "react-icons/fa";
import { motion } from "framer-motion";
import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";

import segurofiscal from "../../img/carruselop/Seguridad Fiscal.png";
import segurofiscal2 from "../../img/carruselop/Seguridad Fiscal2.png";
import protecciondigital from "../../img/carruselop/proteccion digital.png";
import protecciondigital2 from "../../img/carruselop/proteccion digital2.png";
import atencionhospitalaria from "../../img/carruselop/Atención Prehospitalaria.png";
import atencionhospitalaria2 from "../../img/carruselop/Atención Prehospitalaria2.png";
import tiro from "../../img/carruselop/tiro reaccion.png";
import tiro2 from "../../img/carruselop/tiro reaccion2.png";

import { TypingEffect } from "../../components/motion/TypingEffect";  
import { LettersPullUp } from "../../components/motion/LettersPullUp";  

const MotionBox = motion.div;
const MotionListItem = motion(ListItem);

const courses = [
  {
    title: "Protección Digital",
    description: (
      <>
        Curso personal para la <TypingEffect text="prevención de ataques" className="font-bold text-red-500" />{" "}
        informáticos de equipos de cómputo y móviles.
      </>
    ),
    items: [
      "Contraseñas seguras",
      "Protección en correos electrónicos",
      "Navegación segura en sitios web",
      "Gestión de seguridad en redes sociales",
      "Protección avanzada de dispositivos",
    ],
    footerNote: "Apto para personas con o sin conocimientos técnicos.",
    images: [protecciondigital, protecciondigital2],
  },
  {
    title: "Atención Prehospitalaria",
    description: (
      <>
        Curso <TypingEffect text="teórico-práctico" className="font-bold text-red-500" />{" "}
        diseñado para formar líderes capaces de actuar ante emergencias.
      </>
    ),
    items: [
      "Entrenamiento de brigadistas",
      "Tratamiento de quemaduras",
      "Manejo de hemorragias",
    ],
    footerNote: "Dirigido a personal de emergencias y brigadistas.",
    images: [atencionhospitalaria, atencionhospitalaria2],
  },
  {
    title: "Seguridad Fiscal",
    description: (
      <>
        Curso para personas físicas que deseen manejar de forma autónoma sus{" "}
        <TypingEffect text="obligaciones fiscales" className="font-bold text-red-500" />.
      </>
    ),
    items: [
      "Requisitos para personas físicas",
      "Declaración de sueldos y salarios",
      "Introducción al RESICO",
    ],
    footerNote: "Recomendado para emprendedores y profesionistas independientes.",
    images: [segurofiscal, segurofiscal2],
  },
  {
    title: "Tiro de Reacción",
    description: (
      <>
        Curso intensivo de{" "}
        <TypingEffect text="defensa personal" className="font-bold text-red-500" />{" "}
        utilizando técnicas y herramientas especializadas.
      </>
    ),
    items: [
      "Defensa personal efectiva",
      "Identificación del ojo dominante",
      "Desarrollo de la memoria muscular",
      "Técnicas de sujeción",
      "Entrenamiento práctico",
    ],
    footerNote: "Apto para personas de todos los niveles, tanto principiantes como avanzados, interesadas en adquirir y perfeccionar habilidades en técnicas de reacción rápida con equipamiento especializado.",
    images: [tiro, tiro2],
  },
];

const CardsOp = () => {
  return (
    <div className="max-w-6xl mx-auto p-4 mt-16">
      {courses.map((course, index) => (
        <CourseCard 
          key={index} 
          course={course} 
          delay={index * 0.2} 
          reverse={index % 2 !== 0}  // Si es impar, reverse=true
        />
      ))}
    </div>
  );
};

const CourseCard = ({ course, delay, reverse }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === course.images.length - 1 ? 0 : prevIndex + 1));
  }, [course.images.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? course.images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => nextSlide(), 4000);
    }
    return () => clearInterval(intervalId);
  }, [isPlaying, nextSlide]);

  return (
    <div className="bg-white rounded-xl shadow-2xl overflow-hidden md:grid md:grid-cols-2 mt-20 mb-20">
      {/* Sección de Texto */}
      <MotionBox
        className={`p-8 md:p-12 flex flex-col justify-center ${reverse ? "md:order-last" : ""}`}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false, amount: 0.3 }}
        transition={{ duration: 0.8, delay }}
        style={{ minHeight: "600px" }} 
      >
        <Heading className="text-4xl font-bold mb-6 text-gray-800 tracking-tight">
          <LettersPullUp text={course.title} />
        </Heading>
        <Text className="text-gray-600 text-lg mb-8 leading-relaxed">{course.description}</Text>
        <UnorderedList className="text-gray-700 text-lg leading-relaxed list-disc pl-6 space-y-2">
          {course.items.map((item, index) => (
            <MotionListItem
              key={index}
              initial={{ opacity: 0, x: -10 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: false, amount: 0.3 }}
              transition={{ delay: index * 0.2, duration: 0.5 }}
            >
              <span className="font-semibold">{item}</span>
            </MotionListItem>
          ))}
        </UnorderedList>
        {course.footerNote && (
          <Text className="text-gray-600 text-base mt-4 italic">{course.footerNote}</Text>
        )}
      </MotionBox>

      {/* Sección del Carrusel de Imágenes */}
      <div className={`relative h-[400px] md:h-full ${reverse ? "md:order-first" : ""}`} tabIndex="0">
        {course.images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              src={image}
              alt={`${course.title} slide ${index + 1}`}
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
        ))}

        {/* Controles del Carrusel */}
        <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-4">
          <button
            onClick={prevSlide}
            className="p-2 bg-black/50 text-white rounded-full hover:bg-black/70 transition"
            aria-label="Previous slide"
          >
            <FaChevronLeft />
          </button>
          <button
            onClick={() => setIsPlaying(!isPlaying)}
            className="p-2 bg-black/50 text-white rounded-full hover:bg-black/70 transition"
            aria-label={isPlaying ? "Pause slideshow" : "Play slideshow"}
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button
            onClick={nextSlide}
            className="p-2 bg-black/50 text-white rounded-full hover:bg-black/70 transition"
            aria-label="Next slide"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardsOp;