import React from 'react';
import quienessomos from '../../img/pagetatnin/quienesformarmantatnin.jpeg';
import RotatingText from '../motion/RotatingText';
import TiltedCard from '../motion/TiltedCard';

const CardWho = () => {
  return (
    <>
      <div className="container mx-auto px-4 py-8">
        {/* Sección con grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center h-auto">
          {/* Imagen a la izquierda */}
          <div className="flex justify-center md:justify-start">
            <TiltedCard
              imageSrc={quienessomos}
              altText="¿Quiénes somos?"
              captionText="Kendrick Lamar - GNX"
              containerHeight="300px"
              containerWidth="300px"
              imageHeight="300px"
              imageWidth="300px"
              rotateAmplitude={12}
              scaleOnHover={1.2}
              showMobileWarning={false}
              showTooltip={false}
              displayOverlayContent={true}
              overlayContent={
                <p className="tilted-card-demo-text">
                  ¿Quiénes somos?
                </p>
              }
            />
          </div>

          {/* Contenido a la derecha */}
          <div className="space-y-6 md:text-left">
            <div className="flex items-center text-4xl font-bold">
              <p>¿Quiénes</p>
              <RotatingText
                texts={['somos?', 'están detrás?', 'forman parte?']}
                mainClassName="ml-2 px-2 text-black overflow-hidden py-0.5 rounded-lg"
style={{ backgroundColor: '#7A1612' }}
                staggerFrom="last"
                initial={{ y: '100%' }}
                animate={{ y: 0 }}
                exit={{ y: '-120%' }}
                staggerDuration={0.025}
                splitLevelClassName="overflow-hidden"
                transition={{ type: 'spring', damping: 30, stiffness: 400 }}
                rotationInterval={2000}
              />
            </div>

            <p className="text-lg leading-relaxed">
            Organización experimentada en protección de recursos críticos, forjada con integridad y espíritu de servicio, combinamos las habilidades de nuestras tres divisiones para crear un entorno seguro para el desarrollo de las actividades de nuestros clientes.


            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardWho;