import React from 'react';

import api from '../../../img/pagetatnin/api3.gif';
import user from '../../../img/pagetatnin/user2.gif';
import hardware from '../../../img/pagetatnin/HARDWARE.gif';
import operation from '../../../img/pagetatnin/operation.gif';

const EsquemaCiber = () => {
  const styles = `
    /* =========================================
       CONTENEDOR PRINCIPAL
    ========================================== */
    .ciber-esquema__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin: 0;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      color: #ecf0f1;
      overflow: hidden;
      background: linear-gradient(135deg, #282c34, #181c24);
    }

    .ciber-esquema__wrapper h1 {
      font-size: 2em;
      letter-spacing: 1px;
      margin-bottom: 20px;
      text-align: center;
      padding: 0 10px;
    }

    /* =========================================
       CONTENEDOR DE NODOS
    ========================================== */
    .ciber-esquema__container {
      position: relative;
      width: 80vw;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /* Nodo central */
    .ciber-esquema__center {
      position: absolute;
      width: 160px;
      height: 160px;
      background: linear-gradient(135deg, #3d4554, #282c34);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 1.6em;
      font-weight: 600;
      border: 4px solid #800000;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
      animation: ciber-esquema-pulse 3s infinite ease-in-out;
    }

    /* Nodos exteriores */
    .ciber-esquema__node {
      position: absolute;
      width: 260px;
      height: 90px;
      background: linear-gradient(135deg, rgba(128, 0, 0, 0.8), rgba(128, 0, 0, 0.5));
      border-radius: 45px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4), -2px -2px 8px rgba(255, 255, 255, 0.1);
      transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    }

    .ciber-esquema__node:hover {
      transform: scale(1.03);
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5), -3px -3px 10px rgba(255, 255, 255, 0.2);
    }

    .ciber-esquema__node span {
      font-size: 1.2em;
      font-weight: 500;
    }

    .ciber-esquema__node img {
      height: 60px;
    }

    /* Posicionamiento de nodos (pantallas grandes) */
    .ciber-esquema__software { top: 10%; left: 10%; }
    .ciber-esquema__hardware { top: 10%; right: 10%; }
    .ciber-esquema__users { bottom: 10%; left: 10%; }
    .ciber-esquema__management { bottom: 10%; right: 10%; }

    /* =========================================
       ANIMACIONES
    ========================================== */
    @keyframes ciber-esquema-pulse {
      0%, 100% { transform: scale(1); }
      50% { transform: scale(1.03); }
    }

    /* Asigna animaciones a los nodos según su orden en el DOM */
    .ciber-esquema__node:nth-child(2) {
      animation: ciber-esquema-nodeLift1 6s infinite ease-in-out;
    }
    .ciber-esquema__node:nth-child(3) {
      animation: ciber-esquema-nodeLift2 6s infinite ease-in-out;
    }
    .ciber-esquema__node:nth-child(4) {
      animation: ciber-esquema-nodeLift3 6s infinite ease-in-out;
    }
    .ciber-esquema__node:nth-child(5) {
      animation: ciber-esquema-nodeLift4 6s infinite ease-in-out;
    }

    @keyframes ciber-esquema-nodeLift1 {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-5px); }
    }

    @keyframes ciber-esquema-nodeLift2 {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(5px); }
    }

    @keyframes ciber-esquema-nodeLift3 {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-5px); }
    }

    @keyframes ciber-esquema-nodeLift4 {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(5px); }
    }

    /* =========================================
       ESTILOS RESPONSIVOS
    ========================================== */

    /* Ajustes para tablets o pantallas medianas */
    @media (max-width: 768px) {
      .ciber-esquema__container {
        width: 90vw;
        height: 75vh; /* Un poco más alto para mayor separación */
      }

      /* Ajustar posición para que no se empalmen */
      .ciber-esquema__software { top: 15%; left: 7%; }
      .ciber-esquema__hardware { top: 15%; right: 7%; }
      .ciber-esquema__users { bottom: 15%; left: 7%; }
      .ciber-esquema__management { bottom: 15%; right: 7%; }

      .ciber-esquema__center {
        width: 120px;
        height: 120px;
        font-size: 1.4em;
      }
      .ciber-esquema__node {
        width: 200px;
        height: 70px;
        padding: 8px;
      }
      .ciber-esquema__node span {
        font-size: 1em;
      }
      .ciber-esquema__node img {
        height: 40px;
      }
    }

    /* Ajustes para pantallas muy pequeñas (teléfonos) */
    @media (max-width: 480px) {
      .ciber-esquema__container {
        width: 95vw;
        height: 80vh; /* Un poco más alto para mayor separación */
      }

      /* Aumentar separaciones desde los bordes */
      .ciber-esquema__software { top: 20%; left: 5%; }
      .ciber-esquema__hardware { top: 20%; right: 5%; }
      .ciber-esquema__users { bottom: 20%; left: 5%; }
      .ciber-esquema__management { bottom: 20%; right: 5%; }

      .ciber-esquema__center {
        width: 100px;
        height: 100px;
        font-size: 1.2em;
      }
      .ciber-esquema__node {
        width: 160px;
        height: 60px;
      }
      .ciber-esquema__node span {
        font-size: 0.9em;
      }
      .ciber-esquema__node img {
        height: 35px;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="ciber-esquema__wrapper">
        <h1>SISTEMAS DE GESTIÓN DE SEGURIDAD DE LA INFORMACIÓN</h1>
        <div className="ciber-esquema__container">
          <div className="ciber-esquema__center">S.G.S.I.</div>

          <div className="ciber-esquema__node ciber-esquema__software">
            <span>SOFTWARE</span>
            <img src={api} alt="Software" />
          </div>

          <div className="ciber-esquema__node ciber-esquema__hardware">
            <span>HARDWARE</span>
            <img src={hardware} alt="Hardware" />
          </div>

          <div className="ciber-esquema__node ciber-esquema__users">
            <span>USERS</span>
            <img src={user} alt="Users" />
          </div>

          <div className="ciber-esquema__node ciber-esquema__management">
            <span>OPERATIONAL MANAGEMENT</span>
            <img src={operation} alt="Operational Management" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EsquemaCiber;