import React from 'react';
import DecryptedText from '../motion/DecryptedText';

const AboutText = () => {
  return (
    <div className="flex items-center justify-center">
      <div style={{ marginTop: '4rem', textAlign: 'center', maxWidth: '800px' }}>
        <DecryptedText
          text="Tatningard Group inicia operaciones en 2012, con la finalidad de mitigar errores operativos derivados de la inexperiencia de organizaciones en seguridad administrativa, decidiendo crear una organización cuya operación fuera determinada por agentes con experiencia en entornos hostiles especializados en análisis e interpretación de acontecimientos reales."
          animateOn="view"
          revealDirection="center"
        />
      </div>
    </div>
  );
};

export default AboutText;