import React from 'react';
import LetterGlitch from '../../components/motion/LetterGlitch';
import SplitText from '../motion/SplitText';

const Backgroud = () => {
  return (
    <div className="relative w-full h-screen">
      {/* Fondo con glitch */}
      <LetterGlitch
        glitchSpeed={50}
        centerVignette={true}
        outerVignette={false}
        smooth={true}
      />

      {/* Título centrado */}
      <div className="absolute inset-0 flex items-center justify-center">
        <SplitText
          text="Tatningard Group"
          className="text-6xl font-bold text-center"
          delay={150}
          animationFrom={{ opacity: 0, transform: 'translate3d(0,50px,0)' }}
          animationTo={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
          easing="easeOutCubic"
          threshold={0.2}
          rootMargin="-50px"
          onLetterAnimationComplete={() => {}}
        />
      </div>
    </div>
  );
};

export default Backgroud;