import React from 'react';
import { motion } from 'framer-motion';

const Title = () => {
  const letters = ['N', 'O', 'S', 'O', 'T', 'R', 'O', 'S'];

  return (
    <motion.div className="w-full h-screen flex flex-col justify-center items-center bg-black">
      {/* Título animado */}
      <div className="flex space-x-2 mb-8">
        {letters.map((letter, index) => (
          <motion.span
            key={index}
            className="text-6xl md:text-8xl font-bold uppercase text-white"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: [0, 1, 1, 0], y: [30, 0, 0, 30], scale: [0.9, 1, 1, 0.9] }}
            transition={{
              delay: index * 0.4,
              duration: 6,
              repeat: Infinity,
              repeatType: 'loop',
              ease: 'easeInOut',
            }}
          >
            {letter}
          </motion.span>
        ))}
      </div>

      {/* Frase descriptiva */}
      <motion.p
        className="text-xl italic md:text-2xl text-gray-300 mt-10 "
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 1, 1, 0.7], scale: [1, 1.05, 1], y: [0, -5, 0] }}
        transition={{ delay: 5, duration: 8, repeat: Infinity, repeatType: 'reverse', ease: 'easeInOut' }}
      >
        Trabajando juntos por un entorno más seguro.
      </motion.p>

      {/* Texto de desliza hacia abajo */}
      <motion.p
        className="absolute bottom-10 text-lg text-red-500"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: [30, 0, 30] }}
        transition={{
          delay: 7,
          duration: 4,
          repeat: Infinity,
          repeatType: 'mirror',
          ease: 'easeInOut',
        }}
      >
        Desliza hacia abajo para explorar más.
      </motion.p>
    </motion.div>
  );
};

export default Title;