import React from 'react';
import Waves from '../motion/Waves';
import { TypingEffect } from '../motion/TypingEffect';

const BackgroundAbout = () => {
  return (
    <div className="relative w-full h-screen">
      <Waves
        lineColor="#fff"
        backgroundColor="rgba(138, 3, 3, 0.9)"
        waveSpeedX={0.02}
        waveSpeedY={0.01}
        waveAmpX={40}
        waveAmpY={20}
        friction={0.9}
        tension={0.01}
        maxCursorMove={120}
        xGap={12}
        yGap={36}
        style={{ width: '100%', height: '100%' }}
      />

      <div className="absolute inset-0 flex items-center justify-center">
        <TypingEffect 
          text="Nosotros" 
          className="font-bold text-white text-6xl md:text-8xl" 
        />
      </div>
    </div>
  );
};

export default BackgroundAbout;