import React from "react";
import { Box, Text, VStack, Container } from "@chakra-ui/react";
import Navbar from "../../components/TnGroupComponents/NavbarTn";

const Privacy = () => {
  return (
    <>
      <Box className="relative z-50">
        <Navbar />
      </Box>
      <Container
        maxW="container.lg"
        mt={{ base: "120px", md: "80px" }} // 120px en móviles y 80px en pantallas medianas en adelante
        py={{ base: 4, md: 8 }}
        px={{ base: 4, md: 8 }}
      >
        <Box
          bg="white"
          color="black"
          p={{ base: 4, md: 8, lg: 12 }} // Padding responsivo
          fontSize={{ base: "sm", md: "md" }} // Tamaño de fuente responsivo
        >
          <VStack spacing={{ base: 2, md: 4 }} align="start"> {/* Espaciado responsivo */}
            <Text
              fontSize={{ base: "lg", md: "xl" }} // Tamaño responsivo para el título
              fontWeight="bold"
              textAlign="center"
              w="full"
            >
              Aviso de privacidad Tatningard Group
            </Text>
            <Text>
              Technology Outcomes SA DE CV y sus subsidiarias (en lo sucesivo “Tatningard Group”), con domicilio en Ámsterdam 240, Cuauhtémoc, 06100 Ciudad de México, CDMX señalado para oír y recibir todo tipo de notificaciones, en calidad de responsable de recabar y tratar sus datos personales y de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su reglamento y demás disposiciones aplicables hace de su conocimiento que la aportación que haga de sus datos Personales. Tatningard Group es el responsable del uso y protección de los mismos, y al respecto le informamos lo siguiente:
            </Text>
            <Text>
              <b>1.-</b> El presente aviso tiene por objeto la protección de sus datos personales, mediante su tratamiento legítimo, controlado e informado, a efecto de garantizar su privacidad, así como su derecho a la autodeterminación informativa.
            </Text>
            <Text>
              <b>2.-</b> Conforme al artículo 3, fracción V, de la Ley, se entiende por Datos Personales: Cualquier información concerniente a una persona física identificada o identificable.
            </Text>
            <Text>
              <b>3.-</b> Tatningard Group, de conformidad a lo dispuesto por la fracción II del artículo 16 de la Ley, será el responsable de tu información personal (Datos Personales). Tatningard Group hará uso de sus datos para fines de contacto y estadísticos, así como para enviarte información sobre nuestros productos y servicios.
            </Text>
            <Text>
              <b>4.-</b> Al participar en el proceso de selección, autoriza a Tatningard Group utilizar y tratar de forma automatizada sus datos personales e información suministrados, los cuales formarán parte de nuestra base de datos con la finalidad de usarlos en forma enunciativa, más no limitativa para: identificarle, ubicarle, comunicarle, contactarle, enviarle información, actualizar nuestra base de datos y obtener estadísticas.
            </Text>
            <Text>
              <b>6.-</b> La temporalidad del manejo de sus Datos Personales será indefinida a partir de la fecha en que nos los proporciones.
            </Text>
            <Text>
              <b>7.-</b> Tatningard Group, como responsable del tratamiento de sus datos personales, está obligada a cumplir con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad tutelados en la Ley; por tal motivo con fundamento en los artículos 13 y 14 de la Ley Federal de Protección de datos personales en posesión de particulares, Tatningard Group se compromete a tratar su información con normas de confidencialidad y seguridad administrativa.
            </Text>
            <Text>
              <b>8.-</b> En términos de lo establecido por el artículo 22 de la Ley, en cualquier momento puede ejercer sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos personales (ARCO).
            </Text>
            <Text>
              <b>8.1.-</b> En caso de que requiera algún cambio deberá enviar un correo a: contacto@tatningard.com bajo el asunto: “Modificación ARCO” en cumplimiento al artículo 29 de la Ley, dicha solicitud deberá contener los siguientes datos:
            </Text>
            <Text>a) Tu nombre y domicilio u otro medio para comunicarte la respuesta a tu solicitud;</Text>
            <Text>b) Los documentos que acrediten tu identidad o, en su caso, la representación legal de la persona que realiza la solicitud a tu nombre;</Text>
            <Text>c) La descripción clara y precisa de los datos personales respecto de los que buscas ejercer alguno de los derechos mencionados en el párrafo anterior, y</Text>
            <Text>d) Cualquier otro elemento o documento que facilite la localización de tus datos personales.</Text>
            <Text>
              <b>8.2.-</b> De conformidad al Artículo 31 de la Ley Federal de Protección de datos personales en posesión de particulares, en caso de solicitar la rectificación de datos personales, adicionalmente deberá indicar las modificaciones a realizarse y aportar la documentación que sustente su petición. La respuesta a su solicitud de acuerdo al artículo 32 de la misma ley, se le comunicará en un plazo de veinte días hábiles, contados desde la fecha en que se recibió, pudiendo ampliarse a veinte días más en los casos que así lo establezcan la Ley; a efecto de que, de resultar procedente, se lleven a cabo las medidas adoptadas para cumplir con su solicitud, mismas que se llevarán a cabo dentro de los quince días hábiles siguientes a la fecha en que se comunique la respuesta.
            </Text>
            <Text>
              <b>9.-</b> En términos generales nuestro sitio web y los servicios que ofrecemos no se destinan a menores de edad. Sin embargo, en caso que se obtengan, usen o almacenen datos personales de menores de edad, se hará con el consentimiento de sus padres o tutores. Cuando un menor se identifica como tal, incorporamos instrucciones para obtener el consentimiento de sus padres o tutores antes de que el menor nos proporcione cualquier dato de carácter personal. Si usted es padre/madre o tutor de algún menor de edad que haya proporcionado sus datos personales sin su consentimiento, puede solicitar el ejercicio de cualquiera de los derechos ARCO enviando un correo electrónico a: contacto@tatningard.com, con las mismas características que el punto número 8 del presente documento.
            </Text>
            <Text>
              <b>10.-</b> Únicamente en los casos descritos en la Ley, tales como: cumplimiento a disposiciones legales, requerimiento de autoridades o transferencias entre nuestras empresas filiales o subsidiarias, se llevará a cabo la transferencia de sus datos personales. En el caso particular de los candidatos a empleados y de los empleados, se realizará transferencia de sus datos en los casos en los que ellos mismos así lo soliciten a efecto de brindar a terceros referencias profesionales o enviar sus currículos a empresas interesadas en ellas.
            </Text>
            <Text>
              <b>11.-</b> Le sugerimos conocer y analizar el contenido de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares toda vez que pueden generarse cambios normativos a los que estamos sujetos.
            </Text>
            <Text>
              <b>12.-</b> Tatningard Group podrá utilizar cookies durante la prestación de servicios del sitio web. Las cookies son ficheros físicos de información personal alojados en el propio terminal del usuario. El usuario tiene la posibilidad de configurar su programa navegador de manera que se impida la creación de archivos cookie o se advierta de la misma.
            </Text>
            <Text>
              <b>13.-</b> Si opta a abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, Tatningard Group no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.
            </Text>
            <Text>
              <b>14.-</b> Se hace de su conocimiento que de acuerdo al Artículo 37 de la Ley Federal de Protección de datos personales en posesión de particulares se podrán hacer las transferencias nacionales o internacionales de datos sin el consentimiento del titular cuando se dé alguno de los supuestos enunciados en el presente Artículo.
            </Text>
            <Text>
              <b>15.-</b> Tatningard Group informa que de conformidad con el artículo 8vo se entenderá que usted como titular consiente tácitamente el tratamiento de sus datos, toda vez que habiéndose puesto a su disposición este aviso de privacidad, no manifestó su oposición.
            </Text>
          </VStack>
        </Box>
      </Container>
    </>
  );
};

export default Privacy;