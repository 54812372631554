import React from "react";
import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

// Creamos versiones "motion" de los componentes Box y Flex para animarlos
const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

// Variantes de animación para el texto y la imagen
const textVariant = {
  hidden: { x: -50, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.8 },
  },
};

const imageVariant = {
  hidden: { x: 50, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.8 },
  },
};

const WhatWeDoSection = ({
  // Si no pasas una imagen, puedes establecer un valor por defecto:
  image = "https://via.placeholder.com/500x300", 
  title = "¿Qué Hacemos?",
  // Texto por defecto si no se pasa una descripción
  description = "Conformado por los mejores agentes de las tres divisiones...",
  altText = "Imagen de la sección",
}) => {
  return (
    <Box py={{ base: 10, md: 16 }}>
      <MotionFlex
        // Contenedor principal en flex, con dirección adaptable
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="center"
        maxW="6xl"
        mx="auto"
        px={4}
        gap={8}
        // Animación de aparición global
        initial="hidden"
        animate="visible"
      >
        {/* Sección de texto */}
        <MotionBox
          variants={textVariant}
          flex="1"
          color="white"
          textAlign={{ base: "center", md: "left" }}
        >
          <Heading as="h2" color="red.500" fontSize="3xl" mb={4}>
            {title}
          </Heading>
          <Text fontSize="lg" maxW="md" mx={{ base: "auto", md: "0" }}>
            {description}
          </Text>
        </MotionBox>

        {/* Sección de la imagen */}
        <MotionBox
          variants={imageVariant}
          flex="1"
          display="flex"
          justifyContent={{ base: "center", md: "flex-end" }}
        >
          <Box
            // Clip diagonal para la imagen
            clipPath="polygon(15% 0, 100% 0, 100% 100%, 0 100%)"
            overflow="hidden"
            w={{ base: "full", md: "auto" }}
            maxW="500px"
          >
            <Image
              src={image}
              alt={altText}
              objectFit="cover"
              w="full"
              h="full"
            />
          </Box>
        </MotionBox>
      </MotionFlex>
    </Box>
  );
};

export default WhatWeDoSection;