import React from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteMenu from './InfiniteMenu';

// Imágenes de las divisiones
import iconInfra from "./iconinfra.png";
import iconOp from "./iconop.png";
import iconCiber from "./icociber.png";

const CirclesDivision = () => {
  const navigate = useNavigate();
  
  const items = [
    {
      image: iconCiber,
      link: '/cybersecurity-info',
      title: 'Ciberseguridad',
    },
    {
      image: iconOp,
      link: '/Operation-info',
      title: 'Operaciones',
    },
    {
      image: iconInfra,
      link: '/infrastructure-info',
      title: 'Infraestructura',
    }
  ];

  return (
    <InfiniteMenu items={items} navigate={navigate} />
  );
};

export default CirclesDivision;