import React from "react";
import { Box, Text, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import fondo from "../img/ciberseguridadinfo.webp";
import DualSectionCard from "../components/componetsDivision/CardCiber";
import Navigation from "../components/componentshomeandbusiness/Navigation";

const MotionHeading = motion(Heading);

const CybersecurityInfo = () => {
  return (
    <div>
       <div className="relative z-50">
          <Navigation />
      </div>

      {/* Fondo principal */}
      <div className="bg-gradient-to-b from-gray-900 to-gray-800 min-h-screen">
        {/* Contenedor del título con imagen de fondo */}
        <Box
          position="relative"
          width="100%"
          height={{ base: "550px", md: "700px" }}  // Más espacio vertical
          backgroundImage={`url(${fondo})`}
          backgroundSize="cover"
          backgroundPosition="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
          px={6}  // Espaciado horizontal extra
        >
          {/* Título animado */}
          <MotionHeading
            animate={{
              scale: [1.1, 1.2, 1.1],
              color: ["#808080", "#FFFFFF"],            
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: "mirror",
            }}
            fontSize={{ base: "3xl", md: "5xl", lg: "6xl" }}
            fontWeight="extrabold"
            textAlign="center"
            color="red.500"
            zIndex={1}
            lineHeight="shorter"
            mb={12}  // Más espacio debajo del título
          >
            Ciberseguridad
          </MotionHeading>

          {/* Sombra para mejor legibilidad */}
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.5)"
            zIndex={0}
          ></Box>
        </Box>

        {/* Texto introductorio */}
        <Box textAlign="center" mt={{ base: 20, md: 32 }} px={6}>
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            color="gray.300"
            textAlign="center"
            maxW="4xl"
            mx="auto"
            mb={20}  // Más espacio debajo del texto
          >
            Protegemos tu negocio con{" "}
            <span className="font-bold text-red.500">soluciones integrales</span> de{" "}
            <span className="font-bold text-red.500">ciberseguridad</span> diseñadas para combatir las{" "}
            <span className="font-bold text-red.500">amenazas digitales</span> y garantizar la{" "}
            <span className="font-bold text-red.500">integridad</span> de tu{" "}
            <span className="font-bold text-red.500">información</span>.
          </Text>
        </Box>

        {/* DualSectionCard con margen superior */}
        <Box mt={{ base: 32, md: 40 }}>
          <DualSectionCard />
        </Box>

        {/* Footer */}
        <Box mt={{ base: 24, md: 40 }}>  {/* Más espacio antes del footer */}
          <Footer />
        </Box>
      </div>
    </div>
  );
};

export default CybersecurityInfo;