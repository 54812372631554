// Operations.jsx
import React from "react";
import OperationsHeader from "../../components/TnGroupComponents/Division/OperationsHeader";
import Footer from '../../components/TnGroupComponents/Footer'
import InfraContent from "../../components/TnGroupComponents/Division/InfraContent";
const Infraestructura = () => {
  return (
    <>
       <OperationsHeader divisionName="Infraestructura" />
      <InfraContent />
      <Footer/>
    </>
  );
};

export default Infraestructura;