import React from 'react';
import Navbar from '../../components/TnGroupComponents/NavbarTn';
import BackgroundAbout from '../../components/TnGroupComponents/BackgroundAbout';
import Footer from '../../components/TnGroupComponents/Footer';
import Mission from '../../components/TnGroupComponents/Mission';
import Vision from '../../components/TnGroupComponents/Vision';
import AboutText from '../../components/TnGroupComponents/AboutText';

const About = () => {
  return (
    <>
      {/* Navbar fijo */}
      <div className="fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>

      {/* Contenedor del background con margen superior */}
      <div className="pt-20">
        <BackgroundAbout />
      </div>

      {/* AboutText con espacio adicional */}
      <div className="pt-32">
        <AboutText />
      </div>

      {/* Misión y visión con mayor espacio entre ellos */}
      <div className="min-h-screen bg-gradient-to-br from-gray-50 via-white to-red-50 p-4 md:p-8 flex flex-col items-center justify-center gap-32 mt-32">
        <Mission />
        <Vision />
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default About;