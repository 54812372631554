import React from 'react';
import { motion } from 'framer-motion';
import how from '../../img/quienessomosnew.png';
import what from '../../img/quehacemosNew.png';
import MissionVision from './MissionVision';

// Subrayado
import UnderlineAnimation from '../motion/UnderlineAnimation';
// Títulos con animación
import { LettersPullUp } from '../motion/LettersPullUp';

const InfoAbout = () => {
  const scrollVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  return (
    <>
      {/* Sección de ¿Quiénes somos? */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.2 }}
        transition={{ duration: 0.8 }}
        variants={scrollVariant}
        className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 justify-items-center py-10 md:py-16 px-4 md:px-20 bg-gray-100 mb-16"
      >
        <motion.img
          src={how}
          alt="¿Quiénes somos?"
          className="w-full h-auto md:max-w-xs lg:max-w-sm rounded-lg shadow-lg object-cover"
          initial={{ rotate: -5 }}
          animate={{ rotate: [0, 5, -5, 0] }}
          transition={{
            duration: 6,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />
        <motion.div
          className="max-w-lg px-4 md:px-0 text-center md:text-left"
          transition={{ duration: 0.8 }}
          variants={scrollVariant}
        >
          <h2 className="text-3xl font-bold mb-4 text-gray-800 tracking-wide">
            <LettersPullUp text="¿Quiénes somos?" />
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
  Tatningard Home & Business nace como una división adicional del grupo empresarial. Comprendemos la importancia de crear{' '}
  <UnderlineAnimation text="entornos seguros" className="text-red-500 font-medium" />{' '}
  y confiables tanto en el sector{' '}
  <UnderlineAnimation text="domiciliario" className="text-red-500 font-medium" />{' '}
  como en{' '}
  <UnderlineAnimation text="negocios" className="text-red-500 font-medium" />. Nos dedicamos exclusivamente a ofrecer{' '}
  <UnderlineAnimation text="servicios" className="text-red-500 font-medium" />{' '}
  y tecnologías de vanguardia diseñadas específicamente para satisfacer las necesidades únicas de este sector.
</p>
        </motion.div>
      </motion.div>

      {/* Sección de ¿Qué hacemos? */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.2 }}
        transition={{ duration: 0.8 }}
        variants={scrollVariant}
        className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 justify-items-center py-10 md:py-16 px-4 md:px-20 bg-gray-100"
      >
        <motion.div
          className="max-w-lg px-4 md:px-0 text-center md:text-left"
          transition={{ duration: 0.8 }}
          variants={scrollVariant}
        >
          <h2 className="text-3xl font-bold mb-4 text-gray-800 tracking-wide">
            <LettersPullUp text="¿Qué hacemos?" />
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            En Tatningard Home & Business, nos destacamos por nuestra capacidad para ofrecer{' '}
            <UnderlineAnimation text="soluciones integrales" className="text-red-500 font-medium" /> y{' '}
            <UnderlineAnimation text="avanzadas" className="text-red-500 font-medium" /> que se adaptan a las necesidades de cada cliente, apoyados en{' '}
            <UnderlineAnimation text="tecnología de vanguardia" className="text-red-500 font-medium" /> y en{' '}
            <UnderlineAnimation text="profesionales altamente capacitados" className="text-red-500 font-medium" />.
          </p>
        </motion.div>
        <motion.img
          src={what}
          alt="¿Qué hacemos?"
          className="w-full h-auto md:max-w-xs lg:max-w-sm rounded-lg shadow-lg object-cover"
          initial={{ rotate: -5 }}
          animate={{ rotate: [0, -5, 5, 0] }}
          transition={{
            duration: 6,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />
      </motion.div>

      {/* Agregar MissionVision */}
      <div className="relative w-full py-20">
        <MissionVision />
      </div>
    </>
  );
};

export default InfoAbout;