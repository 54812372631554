import React, { useState, useEffect } from "react";
import { Box, Image, IconButton } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const MotionBox = motion(Box);

const ImageCarouselInfo = ({ images, interval = 6000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Función para cambiar a la imagen anterior
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Función para cambiar a la siguiente imagen
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Cambiar la imagen automáticamente cada cierto tiempo
  useEffect(() => {
    const autoSlide = setInterval(() => {
      handleNext();
    }, interval);

    // Limpiar el intervalo cuando el componente se desmonta
    return () => clearInterval(autoSlide);
  }, [currentIndex, interval]); // Dependencias para el efecto

  return (
    <Box position="relative" w="100%" maxW="400px" overflow="hidden" borderRadius="md">
      <MotionBox
        key={currentIndex}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ duration: 1.5 }}
      >
        <Image src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} borderRadius="md" />
      </MotionBox>


    </Box>
  );
};

export default ImageCarouselInfo;
