import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export function TypingEffect({ text, className = '' }) {
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(false);
      setTimeout(() => setIsAnimating(true), 3500);  // Pausa antes de reiniciar
    }, 10000); // Ciclo completo de animación cada 10 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <span className={`inline-block ${className}`}>
      {text.split('').map((letter, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0 }}
          animate={isAnimating ? { opacity: 1 } : { opacity: 0 }}
          transition={{
            duration: 0.8,        // Tiempo de aparición por letra
            delay: index * 0.12,  // Retraso entre letras
            ease: 'easeInOut',
          }}
        >
          {letter}
        </motion.span>
      ))}
    </span>
  );
}