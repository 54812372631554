import React from 'react';
import { motion } from 'framer-motion';
import militar1 from '../../img/pagetatnin/militar1.jpg'

export function PresentationStrategicRiskUnit() {
  const titleText = "¿Qué Hacemos?";

  const letterVariants = {
    initial: { opacity: 0, y: 50 },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: i * 0.1,
      },
    }),
  };

  const paragraphVariants = {
    initial: { opacity: 0, y: 20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-4">
      <div className="max-w-7xl w-full relative">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Contenido de texto */}
          <motion.div 
            className="text-white z-10 relative"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl font-bold text-red-600 mb-4 flex overflow-hidden">
              {titleText.split("").map((char, index) => (
                <motion.span
                  key={index}
                  custom={index}
                  variants={letterVariants}
                  initial="initial"
                  animate="animate"
                  className="inline-block"
                  whileHover={{
                    scale: 1.2,
                    color: '#ff0000',
                    transition: { duration: 0.2 }
                  }}
                >
                  {char === " " ? "\u00A0" : char}
                </motion.span>
              ))}
            </h2>
            <motion.p
              className="text-gray-300 text-lg leading-relaxed max-w-xl"
              variants={paragraphVariants}
              initial="initial"
              animate="animate"
            >
              Conformado por los mejores agentes de las tres divisiones, nuestras capacidades, experiencias y entrenamiento son fundamentales para resolver casos complejos. Proporcionamos servicios específicos para asegurar la continuidad crítica en las operaciones de nuestros clientes.
            </motion.p>
          </motion.div>

          {/* Contenedor de la imagen */}
          <motion.div
            className="relative w-full h-[500px] md:h-[600px]"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <motion.div 
              className="absolute inset-0"
              style={{
                clipPath: 'polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%)',
                transform: 'perspective(1000px) rotateY(-15deg)',
              }}
              animate={{
                scale: [1, 1.02, 1],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <img 
                src={militar1}
                alt="Agentes en operación"
                className="w-full h-full object-cover"
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}