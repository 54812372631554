import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";

export function LettersPullUp({ text, className = "", repeatDelay = 5 }) {
  const [animationKey, setAnimationKey] = useState(0); // Clave para reiniciar la animación
  const splittedText = text.split("");
  const ref = useRef(null);

  // Reiniciar la animación después de cierto tiempo
  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationKey((prevKey) => prevKey + 1);
    }, repeatDelay * 1000);

    return () => clearInterval(interval);
  }, [repeatDelay]);

  const pullupVariant = {
    initial: { y: 10, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.05,
      },
    }),
  };

  return (
    <div className="flex justify-center flex-wrap">
      {splittedText.map((char, i) => (
        <motion.div
          key={`${animationKey}-${i}`} // Actualiza la clave para reiniciar la animación
          ref={ref}
          variants={pullupVariant}
          initial="initial"
          animate="animate"
          custom={i}
          className={`text-center font-bold tracking-tight ${className}`}
        >
          {char === " " ? <span>&nbsp;</span> : char}
        </motion.div>
      ))}
    </div>
  );
}